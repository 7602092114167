import React, { useEffect, useState } from "react"
import { Route, Switch } from "react-router-dom"
import NewObject from "../../../overbox/sheet/seller/NewObject"
import ObjectSheet from "../../../sheet/ObjectSheet"
import Listing from "../../../table/Listing"
import usePrevious from "../../../../class/tool/usePrevious"
import SellerController from "../../../../stories/_setting/Sellers/SellerController"
import SellerGroupController from "../../../../stories/_setting/SellerGroups/SellerGroupController"
import FormBuilder from "../../../../class/tool/FormBuilder"
import ListingContext from "../../../../context/ListingContext"
import '../../../../css/page/content/seller/Seller.css'

const Sellers = props => {
    const { page } = props
    const item = "sellers"
    const itemClass = "seller"
    const titleWindow = "Vendeurs"
    const placeholderSearch = "un vendeur"
    const titleNbItems = "vendeurs"
    const emptyList = "Aucun vendeur"
    const textRemoveButton = "ce vendeur"
    const activeHistory = false
    const queryParams = new URLSearchParams(window.location.search)
    const [ pageSelect, setPageSelect ] = useState(page != null ? page : 1)
    const [ loading, setLoading ] = useState(true)
    const [ groups, setGroups ] = useState([
        {
            key: -2,
            value: "Tous les groupes",
            id: 0,
            type: ""
        }, {
            key: -1,
            value: "----------",
            id: 0,
            type: ""
        }
    ])
    const [ list, setList ] = useState([])
    const [ filterValues, setFilterValues ] = useState({})
    const [ pagination, setPagination ] = useState(null)
    const [ perPage, setPerPage ] = useState(25)
    const [ sortingName, setSortingName ] = useState("updated_at")
    const [ sortingValue, setSortingValue ] = useState("desc")
    const [ input, setInput ] = useState(queryParams.get("input") !== null ? queryParams.get("input") : "")
    const [ groupId, setGroupId ] = useState(queryParams.get("group_id") !== null ? queryParams.get("group_id") : -2)
    const [ filterRows, setFilterRows ] = useState([])
    const prevPerPage = usePrevious(perPage)
    const prevGroupId = usePrevious(groupId)
    const prevInput = usePrevious(input)

    const initFilters = () => {
        let filtersTmp = []

        /*
        filtersTmp.push({
            attribute: "group_id",
            inputType: "select",
            returnType: "int",
            list: groups,
            dictionary: null,
            classnameInput: "select marginTop leftAlign"
        })
         */

        filtersTmp.push({
            attribute: "input",
            inputType: "text",
            returnType: "string",
            classnameInput: "text marginTop",
            placeholder: "Rechercher " + placeholderSearch,
        })

        setFilterValues(prev => ({
            ...prev,
            group_id: groupId,
            input: input
        }))
        setFilterRows(filtersTmp)
    }
    const getSellers = (pGroupId= 0, pTextInput = "", pPage = 1, pPerPage = 25, pSortingName = "", pSortingValue = "") => {
        let controller;
        let paramGroupId = pGroupId !== 0 ? pGroupId : groupId;
        let paramInput = pTextInput !== "" ? pTextInput : input;
        let paramPage = pPage !== 1 ? pPage : pageSelect;
        let paramPerPage = pPerPage !== 25 ? pPerPage : perPage;
        let paramSortingName = pSortingName !== "" ? pSortingName : sortingName;
        let paramSortingValue = pSortingValue !== "" ? pSortingValue : sortingValue;

        setLoading(true);

        if (paramGroupId < 0) {
            controller = new SellerController();
            controller._callback = handleGetSellers;
            controller.index(paramInput, paramPage, paramPerPage, paramSortingName, paramSortingValue);
        }
        else {
            controller = new SellerGroupController();
            controller._callback = handleGetSellers;
            controller.indexSellers(paramGroupId, paramInput, paramPage, paramPerPage, paramSortingName, paramSortingValue);
        }
    }
    const handleGetSellers = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                setList(list)
                setPagination(pagination !== undefined ? pagination : null )
                break
            default:
                console.log(error)
                break
        }

        setLoading(false)
    }
    const getGroupsTree = () => {
        const controller = new SellerGroupController();
        controller._callback = handleGetGroupsTree;
        controller.index("", 0, 0, true);
    }
    const handleGetGroupsTree = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                let groupsTmp = [
                    {
                        key: -2,
                        value: "Tous les groupes",
                        id: 0,
                        type: ""
                    }, {
                        key: -1,
                        value: "----------",
                        id: 0,
                        type: ""
                    }
                ];
                let i, j;
                let loop = 0;

                for (i in list) {
                    groupsTmp.push({
                        key: loop++,
                        value: list[i].name,
                        id: list[i].id
                    });
                }

                setGroups(groupsTmp);
                break
            default:
                console.log(error)
                break
        }
    }
    const updateFilterGroups = () => {
        if (groups.length <= 2) return

        let filtersTmp = filterRows.slice()
        filtersTmp[0].list = groups
        setFilterRows(filtersTmp)
    }
    const handleChange = (attribute, returnType, val, strict = false) => {
        const obj = FormBuilder.handleChange(filterRows, setFilterValues, attribute, returnType, val, strict)

        switch (attribute) {
            case "group_id":
                setGroupId(obj.value)
                break
            case "input":
                setInput(obj.value)
                break
            default: break
        }
    }
    const updatePageSelect = page => {
        setPageSelect(page);
    }
    const handleRefresh = (force = true) => {
        if (!force) {
            if (perPage !== prevPerPage || groupId !== prevGroupId || input !== prevInput) {
                if (pageSelect !== 1) {
                    setPageSelect(1)
                    return
                }
            }
        }

        getSellers(groupId, input, pageSelect, perPage, sortingName, sortingValue);
    }
    const handleUpdate = object => {
        let index = list.findIndex(item => item.id === object.id);
        if (index < 0) return;

        let listTmp = list.slice();
        let keys = Object.keys(listTmp[index]);
        let key = "";

        for(let i in keys) {
            key = keys[i];

            if (object[key] !== undefined)
                listTmp[index][key] = object[key];
        }

        setList(listTmp)
    }
    const handleRemove = () => {
        handleRefresh()
    }

    const model = [
        {
            "class": "name",
            "sortingParam": "name",
            "title": "Nom",
            "attributes": ["fullname"],
            "type": "text"
        }, {
            "class": "reference",
            "sortingParam": "reference",
            "title": "Référence",
            "attributes": ["reference"],
            "type": "text"
        }, {
            "class": "group",
            "sortingParam": "group",
            "title": "Groupe",
            "attributes": ["groupName"],
            "type": "text"
        }
    ]
    const options = [
        {
            "class": "add",
            "title": "Créer " + placeholderSearch,
            "action": "",
            "link": "/" + item + "/new"
        }
    ]
    const secondaryOptions = [
        {
            "class": "blue addMany",
            "title": "Ajouter plusieurs " + titleNbItems,
            "action": "handleAddMany",
            "link": ""
        }
    ]

    useEffect(() => {
        document.title = "Back office - " + titleWindow

        getGroupsTree()
        initFilters()
    }, [])
    useEffect(() => {
        updateFilterGroups()
    }, [groups])
    useEffect(() => {
        handleRefresh(false)
    }, [groupId, perPage, pageSelect, sortingName, sortingValue])
    useEffect(() => {
        if (prevInput === undefined) return

        const timeoutInput = setTimeout(() => {
            handleRefresh(false)
        }, 1000)

        return () => clearTimeout(timeoutInput)
    }, [input])

    return(
        <ListingContext.Provider value={{page: pageSelect}}>
            <Listing
                item={ item }
                itemClass={ itemClass }
                placeholderSearch={ placeholderSearch }
                titleNbItems={ titleNbItems }
                emptyList={ emptyList }
                model={ model }
                options={ options }
                secondaryOptions={ secondaryOptions }
                filters={ filterRows }
                filterValues={ filterValues }
                changeFilters={ handleChange }
                activeHistory={ activeHistory }
                page={ page }
                pageSelect={ pageSelect }
                updatePageSelect={ updatePageSelect }
                list={ list }
                loading={ loading }
                pagination={ pagination }
                checkable={ false }
                openable={ true }
                setPerPage={ setPerPage }
                sortingName={ sortingName }
                sortingValue={ sortingValue }
                setSortingName={ setSortingName }
                setSortingValue={ setSortingValue }
            />
            <Switch>
                <Route exact path={"/" + item + "/new"}>
                    <NewObject handleIndex={ handleRefresh } />
                </Route>
                <Route exact path={"/" + item + "/:id/(information|barcodes)"}>
                    <ObjectSheet
                        objectType={ itemClass }
                        previousLink={ item }
                        textRemoveButton={ textRemoveButton }
                        handleUpdate={handleUpdate}
                        handleRemove={handleRemove}
                    />
                </Route>
            </Switch>
        </ListingContext.Provider>
    );
}

export default Sellers;
