import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import LoaderCircle from "../../loader/LoaderCircle";
import PaymentMethodController from "../../../stories/_setting/PaymentMethods/PaymentMethodController";
import PredefinedPaymentMethodController from "../../../stories/_setting/PredefinedPaymentMethods/PredefinedPaymentMethodController";
import '../../../css/form/Form.css';
import FormBuilder from "../../../class/tool/FormBuilder";

const FormStart = props => {
    const item = "paymentmethods"
    const controller = new PaymentMethodController()

    const { object, handleIndex } = props;
    const [ reload, setReload ] = useState(false);
    const [ loadingPredefinedPaymentMethods, setLoadingPredefinedPaymentMethods ] = useState(true);
    const [ values, setValues ] = useState({});
    const [ errors, setErrors ] = useState([]);
    const [ saving, setSaving ] = useState(false);
    const [ globalError, setGlobalError ] = useState("");
    const [ rows, setRows ] = useState([
        {
            label: "Type prédéfini",
            attribute: "predefined_payment_method_id",
            inputType: "select",
            returnType: "int",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucun"
        },
        {
            label: "Nom",
            attribute: "name",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Nom",
            emptyText: "Aucun"
        },
        {
            label: "Réference",
            attribute: "reference",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Réference",
            emptyText: "Aucune"
        },
        {
            noLabel: true,
            label: "Ouvrir le tiroir caisse",
            attribute: "openCashRegister",
            inputType: "switch",
            returnType: "int",
            classnameInput: ""
        },
        {
            noLabel: true,
            label: "Générer un avoir",
            attribute: "assetManagement",
            inputType: "switch",
            returnType: "int",
            classnameInput: ""
        },
        {
            label: "Lier à un matériel",
            attribute: "peripheralTarget",
            inputType: "select",
            returnType: "string",
            list: [
                {
                    value: "Aucun",
                    id: null
                }, {
                    value: "----------",
                    id: null
                }, {
                    value: "tpe",
                    id: 1
                }
            ],
            dictionary: {
                "Aucun": "Aucun",
                "----------": "----------",
                "tpe": "TPE",
                //"glory": "GLORY"
            },
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucun"
        }
    ]);

    const getPredefinedPaymentMethods = () => {
        const controller = new PredefinedPaymentMethodController();
        controller._callback = handleGetPredefinedPaymentMethods;
        controller.index();
    }
    const handleGetPredefinedPaymentMethods = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                let rowsTmp = rows.slice();
                let predefinedPaymentMethods = [
                    {
                        value: "Aucun",
                        id: null
                    }, {
                        value: "----------",
                        id: null
                    }
                ];

                for (let i in list) {
                    if (list[i].hidden === 1) continue;

                    predefinedPaymentMethods.push({
                        value: list[i].name,
                        id: list[i].id,
                        object: list[i]
                    });
                }

                rowsTmp[0].list = predefinedPaymentMethods;
                setRows(rowsTmp);

                break
            default:
                setGlobalError("Impossible de récupérer les modes prédéfinis")
                break
        }

        setLoadingPredefinedPaymentMethods(false);
    }
    const initValues = () => {
        controller.setFormValues(object, setValues)
    }
    const handleChange = (attribute, returnType, val, strict = false) => {
        FormBuilder.handleChange(rows, setValues, attribute, returnType, val, strict);
    }
    const callToSave = () => {
        setGlobalError("");
        reinitAllEdits();
        save();
    }
    const reinitAllEdits = () => {
        setErrors([]);
    }
    const returnUpdates = () => {
        return controller.returnUpdatesFromCompare(object, values);
    }
    const check422Errors = errorDatas => {
        setGlobalError("Certaines données sont invalides");

        if(errorDatas !== undefined) {
            var keys = Object.keys(errorDatas);
            var fields = ["reference"];

            for(var item in fields)
                if(keys.includes(fields[item]))
                    defineErrors(fields[item], false);
        }
    }
    const defineErrors = (type, empty) => {
        let errorsTmp = errors.slice();

        switch (type) {
            case "name":
                if(empty) errorsTmp["name"] = "Vous devez saisir un nom";
                else errorsTmp["name"] = "Ce nom n'est pas valide";
                break;
            default: break;
        }

        setErrors(errorsTmp);
    }
    const handleReturnSave = (response, error, status) => {
        setSaving(false);

        switch (status) {
            case 201:
                setReload(true);
                handleIndex();
                break;
            case 422:
                check422Errors(error.response.data);
                break;
            default:
                setGlobalError("Une erreur s'est produite lors de l'enregistrement");
                break;
        }
    }
    const save = () => {
        const datas = returnUpdates();

        if(Object.keys(datas).length === 0) return;

        setSaving(true);

        if (datas.peripheralTarget !== undefined && datas.peripheralTarget !== null) {
            datas.peripheralTarget = datas.peripheralTarget.toLowerCase();
        }

        if (datas.predefined_payment_method_id !== undefined && datas.predefined_payment_method_id === null) {
            delete datas.predefined_payment_method_id;
        }

        controller._callback = handleReturnSave;
        controller.post(datas);
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault();
        callToSave();
    }

    useEffect(() => {
        getPredefinedPaymentMethods();
        initValues();
    }, []);

    return (
        <div className="clearing">
            {
                reload && <Redirect to={ "/" + item } />
            }
            {
                loadingPredefinedPaymentMethods
                    ? <div className="wrapCenterLoader">
                        <LoaderCircle
                            display="loader restGETInForm"
                            strokeWidth="8"/>
                    </div>
                    : <form className="form" onSubmit={handleSubmit}>
                        {
                            globalError !== "" && <p className="globalError">{globalError}</p>
                        }
                        {
                            rows.map((row, index) => (
                                <div key={ index } className="clearing">
                                    {
                                        (row.hidden === undefined || !row.hidden)
                                        && <div className="clearing">
                                            {
                                                row.noLabel === undefined
                                                && <label className={ row.classnameLabel }>{ row.label }</label>
                                            }
                                            {
                                                FormBuilder.buildInputByType(row, values, errors, handleChange, null, null, handleSubmit, null, null, null, index === 0)
                                            }
                                        </div>
                                    }
                                </div>
                            ))
                        }
                        <button className={"submit " + (saving ? "hide" : "")}>
                            {
                                saving
                                    ? "Enregistrement..."
                                    : "Enregistrer"
                            }
                            <LoaderCircle display="loader submitForm " hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                        </button>
                        <Link to={ "/" + item }>
                            <button className="cancel align">Fermer</button>
                        </Link>
                        <div className="clearing" />
                    </form>
            }
        </div>
    )
}

export default FormStart;
