import React, {useEffect, useState} from "react";
import $ from "jquery";
import moment from "moment";
import "moment/locale/fr";
import StringTools from "../../../../class/tool/StringTools";
import CalendarIcon from "../../../icons/CalendarIcon";
import LeftArrow from "../../../icons/LeftArrow";
import RightArrow from "../../../icons/RightArrow";
import "../../../../css/form/DateSelector.css";
import Store from "../../../../stories/_account/Stores/Store";
import Company from "../../../../stories/_account/Companies/Company";

const DateSelector = props => {
    moment.locale("fr");

    const { random, attribute, returnType, classname, type, value, change, validCalendar, noCompare } = props;
    const [ calendarOpened, setCalendarOpened ] = useState(false);
    const [ calendars, setCalendars ] = useState([]);
    const [ nbClick, setNbClick ] = useState(1);
    const env = JSON.parse(localStorage.getItem('env'));
    const idRandom = random !== undefined ? random : Math.floor(Math.random() * 999999);
    const calendarWiths = {
        "day": 430,
        "month": 240,
        "quarter": 240,
        "year": 500
    };
    let inMoveCalendar = false;

    const initCalendars = (reinit = false) => {
        let calendarsTmp = calendars.slice();

        if (calendarsTmp.length === 0 || reinit) {
            calendarsTmp = [];
            let maxValue;

            switch (type) {
                case "day":
                    maxValue = value.end !== null ? value.end : (value.begin !== null ? value.begin : moment().format());

                    calendarsTmp = [
                        {
                            date: moment(maxValue).startOf('month').subtract(2, 'months'),
                            content: buildDaysCalendar(moment(maxValue).startOf('month').subtract(2, 'months'))
                        },
                        {
                            date: moment(maxValue).subtract(1, 'months'),
                            content: buildDaysCalendar(moment(maxValue).startOf('month').subtract(1, 'months'))
                        },
                        {
                            date: moment(maxValue).startOf('month'),
                            content: buildDaysCalendar(moment(maxValue).startOf('month'))
                        },
                        {
                            date: moment(maxValue).startOf('month').add(1, 'months'),
                            content: buildDaysCalendar(moment(maxValue).startOf('month').add(1, 'months'))
                        }
                    ];

                    break;
                case "month":
                    maxValue = value.end !== null ? value.end : (value.begin !== null ? value.begin : moment().format());

                    calendarsTmp = [
                        {
                            date: moment(maxValue).startOf('year').subtract(1, 'years'),
                            content: buildMonthsCalendar(moment(maxValue).startOf('year').subtract(1, 'years'))
                        },
                        {
                            date: moment(maxValue).startOf('year'),
                            content: buildMonthsCalendar(moment(maxValue).startOf('year'))
                        },
                        {
                            date: moment(maxValue).startOf('year').add(1, 'years'),
                            content: buildMonthsCalendar(moment(maxValue).startOf('year').add(1, 'years'))
                        }
                    ];

                    break;
                case "quarter":
                    maxValue = value.end !== null ? value.end : (value.begin !== null ? value.begin : moment().format());

                    calendarsTmp = [
                        {
                            date: moment(maxValue).startOf('year').subtract(1, 'years'),
                            content: buildQuartersCalendar(moment(maxValue).startOf('year').subtract(1, 'years'))
                        },
                        {
                            date: moment(maxValue).startOf('year'),
                            content: buildQuartersCalendar(moment(maxValue).startOf('year'))
                        },
                        {
                            date: moment(maxValue).startOf('year').add(1, 'years'),
                            content: buildQuartersCalendar(moment(maxValue).startOf('year').add(1, 'years'))
                        }
                    ];

                    break;
                case "year":
                    maxValue = value.end !== null ? value.end : (value.begin !== null ? value.begin : moment().format());

                    calendarsTmp = [
                        {
                            content: buildYearsCalendar()
                        }
                    ];

                    break;
                default: break;
            }

            $("#containerCalendar").css({marginLeft: (type !== "year" ? "-" + calendarWiths[type] + "px" : "0")});
        }
        else {
            switch (type) {
                case "day":
                    for (let i = 0; i < calendarsTmp.length; i++) {
                        calendarsTmp[i].content = buildDaysCalendar(moment(calendarsTmp[i].date));
                    }

                    break;
                case "month":
                    for (let i = 0; i < calendarsTmp.length; i++) {
                        calendarsTmp[i].content = buildMonthsCalendar(moment(calendarsTmp[i].date));
                    }

                    break;
                case "quarter":
                    for (let i = 0; i < calendarsTmp.length; i++) {
                        calendarsTmp[i].content = buildQuartersCalendar(moment(calendarsTmp[i].date));
                    }

                    break;
                case "year":
                    calendarsTmp[0].content = buildYearsCalendar();

                    break;
                default: break;
            }
        }

        setCalendars(calendarsTmp);
    }
    const initCalendarPosition = () => {
        const dateSelectorElmt = $("#DateSelector_" + idRandom);
        const calendarElmt = $("#calendar_" + idRandom);

        if (dateSelectorElmt.length === 0 || calendarElmt.length === 0)
            return;

        const offset = dateSelectorElmt.offset();
        const yPos = offset.top + dateSelectorElmt.height() + 15;
        const xPos = offset.left + dateSelectorElmt.width() - calendarElmt.width();

        calendarElmt.css({ top: yPos, left: xPos });
    }
    const buildDatesString = () => {
        let dateFormat;

        switch (type) {
            case "month":
                dateFormat = "MMM YYYY";
                break;
            case "quarter":
                dateFormat = "Qo YYYY";
                break;
            case "year":
                dateFormat = "YYYY";
                break;
            case "day":
            default:
                dateFormat = "DD MMM YYYY";
                break;
        }

        let string = StringTools.capitalizeFirstLetter(moment(value.begin).format(dateFormat));

        if (value.end !== null && !moment(value.begin).isSame(value.end, type)) {
            string += " - " + StringTools.capitalizeFirstLetter(moment(value.end).format(dateFormat));
        }

        return string;
    }
    const buildComparesString = () => {
        let dateFormat;

        switch (type) {
            case "month":
                dateFormat = "MMM YYYY";
                break;
            case "quarter":
                dateFormat = "Qo YYYY";
                break;
            case "year":
                dateFormat = "YYYY";
                break;
            case "day":
            default:
                dateFormat = "DD MMM YYYY";
                break;
        }

        let createdDate = moment();

        switch (env.type) {
            case "company":
                for (let i in env.stores) {
                    if (moment(env.stores[i].created_at).isBefore(createdDate)) {
                        createdDate = moment(env.stores[i].created_at);
                    }
                }

                break;
            case "store":
                createdDate = moment(env.created_at);
                break;
            default: break;
        }

        let string = "";
        let start = moment(value.begin);
        let end = value.end !== null ? moment(value.end) : null;

        switch (type) {
            case "day":
                string += "au ";

                if (Math.abs(moment(start).diff(moment(end), 'days')) > 1) {
                    start.subtract(1, "years");

                    if (end !== null)
                        end.subtract(1, "years");
                }
                else {
                    if (moment(start).subtract(1, "years").isAfter(createdDate)) {
                        start.subtract(1, "years");

                        if (end !== null)
                            end.subtract(1, "years");
                    }
                    else if (moment(start).subtract(1, "weeks").isAfter(createdDate)) {
                        start.subtract(1, "weeks");

                        if (end !== null)
                            end.subtract(1, "weeks");
                    }
                    else {
                        start.subtract(1, "days");

                        if (end !== null)
                            end.subtract(1, "days");
                    }
                }

                break;
            case "month":
                string += "à ";

                if (Math.abs(moment(start).diff(moment(end), 'months')) > 1) {
                    start.subtract(1, "years");

                    if (end !== null)
                        end.subtract(1, "years").endOf("month");
                }
                else {
                    if (moment(start).subtract(1, "years").isAfter(createdDate)) {
                        start.subtract(1, "years");

                        if (end !== null)
                            end.subtract(1, "years").endOf("month");
                    }
                    else {
                        start.subtract(1, "months");

                        if (end !== null)
                            end.subtract(1, "months").endOf("month");
                    }
                }

                break;
            case "quarter":
                string += "au ";

                if (Math.abs(moment(start).diff(moment(end), 'months')) > 3) {
                    start.subtract(1, "years");

                    if (end !== null)
                        end.subtract(1, "years").endOf("month");
                }
                else {
                    if (moment(start).subtract(1, "years").isAfter(createdDate)) {
                        start.subtract(1, "years");

                        if (end !== null)
                            end.subtract(1, "years").endOf("month");
                    }
                    else {
                        start.subtract(1, "quarters");

                        if (end !== null)
                            end.subtract(1, "quarters").endOf("month");
                    }
                }

                break;
            case "year":
                string += "à ";
                start.subtract(1, "years");

                if (end !== null)
                    end.subtract(1, "years");

                break;
            default:
                break;
        }

        string += StringTools.capitalizeFirstLetter(start.format(dateFormat));

        if (end !== null && !start.isSame(end, type)) {
            string += " - " + StringTools.capitalizeFirstLetter(end.format(dateFormat));
        }

        return string;
    }
    const valid = () => {
        open();
        validCalendar();
    }
    const open = () => {
        initCalendarPosition();
        setCalendarOpened(!calendarOpened);
    }
    const toLeft = () => {
        if (inMoveCalendar)
            return;

        inMoveCalendar = true;
        let container = $("#containerCalendar");

        container.animate(
            {
                marginLeft: parseInt(container.css("marginLeft").replace('px', '')) + calendarWiths[type] + "px"
            },
            200, "swing", function() {
                inMoveCalendar = false;

                let calendarsTmp = calendars.slice();
                let baseDate = moment(calendars[0].date);
                let subDate;

                switch (type) {
                    case "day":
                        subDate = baseDate.subtract(1, 'months').format();

                        calendarsTmp.splice(0, 0, {
                            date: moment(subDate),
                            content: buildDaysCalendar(moment(subDate))
                        });

                        break;
                    case "month":
                        subDate = baseDate.subtract(1, 'years').format();

                        calendarsTmp.splice(0, 0, {
                            date: moment(subDate),
                            content: buildMonthsCalendar(moment(subDate))
                        });

                        break;
                    case "quarter":
                        subDate = baseDate.subtract(1, 'years').format();

                        calendarsTmp.splice(0, 0, {
                            date: moment(subDate),
                            content: buildQuartersCalendar(moment(subDate))
                        });

                        break;
                    default: return;
                }

                calendarsTmp.splice(-1);

                setCalendars(calendarsTmp);
                $("#containerCalendar").css({marginLeft: "-" + calendarWiths[type] + "px"});
            }
        );
    }
    const toRight = () => {
        if (inMoveCalendar)
            return;

        inMoveCalendar = true;
        let container = $("#containerCalendar");

        container.animate(
            {
                marginLeft: parseInt(container.css("marginLeft").replace('px', '')) - calendarWiths[type] + "px"
            },
            200, "swing", function() {
                inMoveCalendar = false;

                let calendarsTmp = calendars.slice();
                let baseDate = moment(calendars[calendars.length-1].date);
                let addingDate;

                switch (type) {
                    case "day":
                        addingDate = baseDate.add(1, 'months').format();

                        calendarsTmp.push({
                            date: moment(addingDate),
                            content: buildDaysCalendar(moment(addingDate))
                        });

                        break;
                    case "month":
                        addingDate = baseDate.add(1, 'years').format();

                        calendarsTmp.push({
                            date: moment(addingDate),
                            content: buildMonthsCalendar(moment(addingDate))
                        });

                        break;
                    case "quarter":
                        addingDate = baseDate.add(1, 'years').format();

                        calendarsTmp.push({
                            date: moment(addingDate),
                            content: buildQuartersCalendar(moment(addingDate))
                        });

                        break;
                    default: return;
                }

                calendarsTmp.shift();

                setCalendars(calendarsTmp);
                $("#containerCalendar").css({marginLeft: "-" + calendarWiths[type] + "px"});
            }
        );
    }
    const calendarContent = () => {
        let shortcuts = [];

        switch (type) {
            case "day":
                shortcuts = [
                    {
                        date: moment().startOf('day'),
                        label: "Aujourd'hui"
                    },
                    {
                        date: moment().subtract(1, 'days'),
                        label: "Hier"
                    },
                    {
                        date: moment().startOf("week").subtract(1, 'weeks').day(moment().isoWeekday()),
                        label: StringTools.capitalizeFirstLetter(moment().startOf("week").subtract(1, 'weeks').day(moment().isoWeekday()).format("dddd")) + " dernier"
                    },
                    {
                        date: moment().startOf("week").subtract(1, 'months').day(moment().isoWeekday()),
                        label: StringTools.capitalizeFirstLetter(moment().startOf("week").subtract(1, 'months').day(moment().isoWeekday()).format("dddd")) + ", il y a 1 mois"
                    },
                    {
                        date: moment().startOf("week").subtract(1, 'years').day(moment().isoWeekday()),
                        label: StringTools.capitalizeFirstLetter(moment().startOf("week").subtract(1, 'years').day(moment().isoWeekday()).format("dddd")) + ", il y a 1 an"
                    },
                    {
                        date: moment().subtract(1, 'years'),
                        label: StringTools.capitalizeFirstLetter(moment().subtract(1, 'years').format("ddd DD MMM")) + ", il y a 1 an"
                    }
                ];

                return <>
                    <div className={"arrows " + type}>
                        <LeftArrow classname={"arrow left"} click={toLeft} />
                        <RightArrow classname={"arrow right"} click={toRight} />
                    </div>
                    <div id={"containerCalendar"} className={"container"}>
                        {
                            calendars.map((calendar, index) => (
                                <div key={index} className={"item"}>{calendar.content}</div>
                            ))
                        }
                    </div>
                    <div className={"footer"}>
                        <div className={"shortcuts"}>
                            {
                                shortcuts.map((shortcut, index) => (
                                    <p key={index} className={"short"} onClick={() => { clickOnDate(shortcut.date, true) }}>{ shortcut.label }</p>
                                ))
                            }
                        </div>
                        <p className={"valid"} onClick={valid}>Valider</p>
                    </div>
                </>
            case "month":
                shortcuts = [];

                return <>
                    <div className={"arrows " + type}>
                        <LeftArrow classname={"arrow left"} click={toLeft}/>
                        <RightArrow classname={"arrow right"} click={toRight}/>
                    </div>
                    <div id={"containerCalendar"} className={"container"}>
                        {calendars.map((calendar, index) => (<div key={index} className={"item"}>{calendar.content}</div>))}
                    </div>
                    <div className={"footer"}>
                        <div className={"shortcuts"}>
                            {shortcuts.map((shortcut, index) => (<p key={index} className={"short"} onClick={() => { clickOnDate(shortcut.date, true) }}>{shortcut.label}</p>))}
                        </div>
                        <p className={"valid"} onClick={valid}>Valider</p>
                    </div>
                </>
            case "quarter":
                shortcuts = [];

                return <>
                    <div className={"arrows " + type}>
                        <LeftArrow classname={"arrow left"} click={toLeft}/>
                        <RightArrow classname={"arrow right"} click={toRight}/>
                    </div>
                    <div id={"containerCalendar"} className={"container"}>
                        {calendars.map((calendar, index) => (<div key={index} className={"item"}>{calendar.content}</div>))}
                    </div>
                    <div className={"footer"}>
                        <div className={"shortcuts"}>
                            {shortcuts.map((shortcut, index) => (<p key={index} className={"short"} onClick={() => { clickOnDate(shortcut.date, true) }}>{shortcut.label}</p>))}
                        </div>
                        <p className={"valid"} onClick={valid}>Valider</p>
                    </div>
                </>
            case "year":
                shortcuts = [];

                return <>
                    <div className={"arrows " + type}>
                        <LeftArrow classname={"arrow left"} click={toLeft}/>
                        <RightArrow classname={"arrow right"} click={toRight}/>
                    </div>
                    <div id={"containerCalendar"} className={"container"}>
                        {calendars.map((calendar, index) => (<div key={index} className={"item"}>{calendar.content}</div>))}
                    </div>
                    <div className={"footer"}>
                        <div className={"shortcuts"}>
                            {shortcuts.map((shortcut, index) => (<p key={index} className={"short"} onClick={() => { clickOnDate(shortcut.date, true) }}>{shortcut.label}</p>))}
                        </div>
                        <p className={"valid"} onClick={valid}>Valider</p>
                    </div>
                </>
            default:
                break;
        }
    }
    const buildDaysCalendar = baseDate => {
        baseDate.startOf("month");
        let initialDate = moment(baseDate.format());
        let datas = emptyDictDates();
        let nbDays = baseDate.daysInMonth();
        let index = 0;
        let days = [];
        let indexDays = [0, 1, 2, 3, 4, 5, 6];

        for (let i = 1; i <= 7; i++) {
            days.push(StringTools.capitalizeFirstLetter(moment(initialDate).startOf("week").day(i).format("ddd")));
        }
        for (let i = 1; i <= nbDays; i++) {
            if (index >= datas.length)
                break;

            if (datas[index].week.number === null) {
                datas[index].week.number = baseDate.format("W");
                datas[index].week.year = baseDate.format("YYYY");
            }

            datas[index].days[parseInt(baseDate.format("e"))] = moment(baseDate.format());

            if (parseInt(baseDate.format("e")) === 6)
                index++;

            baseDate.add(1, "days");
        }

        return <>
            <p className={"title" + (initialDate.isSame(moment(), 'month') ? " now" : "")}>{ StringTools.capitalizeFirstLetter(initialDate.format("MMMM YYYY")) }</p>
            <table>
                <tbody>
                    <tr className={"header"}>
                        <th></th>
                        {
                            days.map((day, index) => (
                                <th key={index} className={"light"}><p>{day}</p></th>
                            ))
                        }
                    </tr>
                    {
                        datas.map((object, index) => (
                            <tr key={index}>
                                <th className={"light"}><p className={"week"} onClick={() => { clickOnWeek(object.week) }}>S{ object.week.number }</p></th>
                                {
                                    indexDays.map((day, index2) => (
                                        <td key={index2}>{buildSelectable(object.days[day])}</td>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </>
    }
    const buildMonthsCalendar = baseDate => {
        baseDate.startOf("year");
        let initialDate = moment(baseDate.format());
        let datas = emptyDictDates();
        let index = 0;
        let indexMonth = 0;

        for (let i = 0; i <= 11; i++) {
            if (index >= datas.length)
                break;

            datas[index].year = moment(initialDate.format());
            datas[index].months.push(moment(initialDate.month(i).format()));

            if (indexMonth >= 2) {
                index++;
                indexMonth = 0;
            }
            else {
                indexMonth++;
            }
        }

        return <>
            <p className={"title" + (initialDate.isSame(moment(), 'year') ? " now" : "")}>{ initialDate.format("YYYY") }</p>
            <table>
                <tbody>
                    <tr className={"header empty"}></tr>
                    {
                        datas.map((obj, index) => (
                            <tr key={index}>
                                {
                                    obj.months.map((month, index2) => (
                                        <td key={index2}>{ buildSelectable(month) }</td>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </>
    }
    const buildQuartersCalendar = baseDate => {
        baseDate.startOf("year");
        let initialDate = moment(baseDate.format());
        let datas = emptyDictDates();

        datas[0].year = moment(initialDate.format());

        for (let i = 1; i < 5; i++) {
            datas[0].quarters.push(moment(initialDate.quarter(i).format()));
        }

        return <>
            <p className={"title" + (initialDate.isSame(moment(), 'year') ? " now" : "")}>{ initialDate.format("YYYY") }</p>
            <table>
                <tbody>
                    <tr className={"header empty"}></tr>
                    <tr>
                        {
                            datas[0].quarters.map((quarter, index) => (
                                <td key={index}>{ buildSelectable(quarter) }</td>
                            ))
                        }
                    </tr>
                </tbody>
            </table>
        </>
    }
    const buildYearsCalendar = () => {
        let baseDate = moment().startOf("year");
        let initialDate = moment(baseDate.format());
        let datas = emptyDictDates();
        let minDate = getMinYear();
        let count = 0;
        let index = 0;
        console.log("minDate", minDate);
        for (let i = parseInt(minDate.format("YYYY")); i <= parseInt(moment().format("YYYY")); i++) {
            datas[index].years[count] = moment(initialDate.year(i).format());

            count++;

            if (count === 5) {
                datas.push({
                    years: [null, null, null, null, null]
                });

                count = 0;
                index++;
            }
        }

        return <>
            <table>
                <tbody>
                <tr className={"header empty"}></tr>
                {
                    datas.map((line, index) => (
                        <tr key={index}>
                            {
                                line.years.map((year, index2) => (
                                    <td key={index2}>{buildSelectable(year)}</td>
                                ))
                            }
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </>
    }
    const emptyDictDates = () => {
        let datas = [];

        switch (type) {
            case "day":
                for (let i = 0; i < 5; i++) {
                    datas.push({
                        week: {
                            number: null,
                            year: null
                        },
                        days: {
                            0: null,
                            1: null,
                            2: null,
                            3: null,
                            4: null,
                            5: null,
                            6: null
                        }
                    })
                }

                break;
            case "month":
                for (let i = 0; i < 4; i++) {
                    datas.push({
                        year: null,
                        months: []
                    })
                }

                break;
            case "quarter":
                datas.push({
                    year: null,
                    quarters: []
                });

                break;
            case "year":
                datas.push({
                    years: [null, null, null, null, null]
                });

                break;
            default: break;
        }

        return datas;
    }
    const getMinYear = () => {
        let env = JSON.parse(localStorage.getItem("env"));
        let minDate = moment();

        switch (env.type) {
            case "company":
                if (env.stores !== undefined) {
                    for (let i in env.stores) {
                        if (env.stores[i].created_at === undefined || env.stores[i].created_at === null)
                            continue;

                        if (moment(env.stores[i].created_at).isBefore(minDate, "year"))
                            minDate = moment(env.stores[i].created_at);
                    }
                }
                else {
                    let company = new Company(JSON.parse(localStorage.getItem("company")));
                    minDate = moment(company.created_at);
                }

                break;
            case "store":
                let store = new Store(JSON.parse(localStorage.getItem("store")));
                minDate = moment(store.created_at);

                break;
            default: break;
        }

        return minDate;
    }
    const buildSelectable = object => {
        if (object == null)
            return <></>;

        let isNow = moment().isSame(object, type);
        let isSelected

        switch (type) {
            case "day":
                isSelected = dateIsSelected(object);

                return <p
                    className={"selectable" + (isNow ? " now" : "") + (isSelected.result ? " selected " + isSelected.place : "")}
                    onClick={() => { clickOnDate(object) }}>
                    { object.format("D") }
                </p>
            case "month":
                isSelected = dateIsSelected(object);

                return <p
                    className={"selectable" + (isNow ? " now" : "") + (isSelected.result ? " selected " + isSelected.place : "")}
                    onClick={() => { clickOnDate(object) }}>
                    { StringTools.capitalizeFirstLetter(object.format("MMM")) }
                </p>
            case "quarter":
                isSelected = dateIsSelected(object);

                return <p
                    className={"selectable" + (isNow ? " now" : "") + (isSelected.result ? " selected " + isSelected.place : "")}
                    onClick={() => { clickOnDate(object) }}>
                    { StringTools.capitalizeFirstLetter(object.format("Qo")) }
                </p>
            case "year":
                isSelected = dateIsSelected(object);

                return <p
                    className={"selectable" + (isNow ? " now" : "") + (isSelected.result ? " selected " + isSelected.place : "")}
                    onClick={() => { clickOnDate(object) }}>
                    { StringTools.capitalizeFirstLetter(object.format("YYYY")) }
                </p>
            default: break;
        }

    }
    const dateIsSelected = date => {
        let result = {
            result: false,
            place: ""
        }

        if (value === null)
            return result;

        if (value.end !== null) {
            result.result = date.isBetween(value.begin, value.end, type, "[]");

            if (result.result) { // calcul place
                let start = false;
                let end = false;

                if (moment(value.begin).isSame(date, type))
                    start = true;

                if (moment(value.end).isSame(date, type))
                    end = true;

                if (start && !end)
                    result.place = "start";
                else if (!start && end)
                    result.place = "end";
                else if (start && end)
                    result.place = "alone";
                else
                    result.place = "between";
            }
        }
        else {
            if (moment(value.begin).isSame(date, type)) {
                result.result = true;
                result.place = "alone";
            }
        }

        return result;
    }
    const clickOnWeek = week => {
        const begin = moment(week.year, "YYYY").week(week.number).startOf('week');
        const end = moment(week.year, "YYYY").week(week.number).endOf('week');

        if (nbClick === 1) {
            change({
                begin: begin.format(),
                end: end.format()
            });

            setNbClick(2);
        }
        else {
            if (begin.isBefore(value.begin)) {
                change({
                    begin: begin.format(),
                    end: value.end
                });
            }
            else {
                change({
                    begin: value.begin,
                    end: end.format()
                });
            }

            setNbClick(1);
        }
    }
    const clickOnDate = (date, only = false) => {
        if (only) {
            change({
                begin: date.startOf(type).format(),
                end: date.startOf(type).endOf(type).format()
            });

            open();
        }
        else {
            if (nbClick === 1) {
                change({
                    begin: date.startOf(type).format(),
                    end: date.startOf(type).endOf(type).format()
                });

                setNbClick(2);
            }
            else {
                if (date.isBefore(value.begin)) {
                    change({
                        begin: date.startOf(type).format(),
                        end: moment(value.begin).endOf(type).format()
                    });
                }
                else {
                    change({
                        begin: value.begin,
                        end: date.endOf(type).format()
                    });
                }

                setNbClick(1);
            }
        }
    }

    useEffect(() => {
        initCalendars();
    }, [value]);
    useEffect(() => {
        if (calendarOpened) {
            setNbClick(1);
            initCalendars(true);

            window.addEventListener('resize', initCalendarPosition);
            window.addEventListener('scroll', initCalendarPosition);
        }
        else {
            window.removeEventListener('resize', initCalendarPosition);
            window.removeEventListener('scroll', initCalendarPosition);
        }
    }, [calendarOpened]);
    useEffect(() => {
        initCalendars(true);
        initCalendarPosition();
    }, [type]);

    return (
        <>
            <div id={"DateSelector_" + idRandom} className={"DateSelector" + (classname !== undefined ? " " + classname : "") + (calendarOpened ? " opened" : "")} onClick={open}>
                <CalendarIcon classname={"icon"}/>
                <div className={"containerText"}>
                    <p className={"text" + (noCompare ? " noSubText" : "")}>{buildDatesString()}</p>
                    {!noCompare && <p className={"subText"}>comparé {buildComparesString()}</p>}
                </div>
            </div>
            <div id={"calendar_" + idRandom} className={"calendar " + type + (calendarOpened ? " visible" : "")}>
                { calendarContent() }
            </div>
        </>
    )
}

export default DateSelector;
