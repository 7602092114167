import React, { useState } from "react";

const ImportBox = props => {
    const itemClass = "import";
    const { type, handleClose } = props;
    const [ globalError, setGlobalError ] = useState(null);

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className={ "overbox " + itemClass }>
                    {
                        globalError !== null
                        && <p className="error">{ globalError }</p>
                    }

                </div>
            </div>
        </div>
    )
}

export default ImportBox;
