import React, {useContext, useEffect, useState} from "react"
import moment from "moment"
import LoaderCircle from "../../loader/LoaderCircle"
import Options from "../../table/Options"
import ConfirmBox from "../asking/ConfirmBox"
import CustomDate from "./CustomDate"
import AlertBox from "../alert/AlertBox"
import CompanyController from "../../../stories/_account/Companies/CompanyController"
import CatalogController from "../../../stories/_catalog/Catalogs/CatalogController"
import FormBuilder from "../../../class/tool/FormBuilder"
import PosScreenController from "../../../stories/_setting/PosScreens/PosScreenController";
import BackofficeContext from "../../../context/BackofficeContext";
import "../../../css/overbox/ToDeployProcess.css"
moment.locale("fr")

const ToDeployProcess = props => {
    const { type, handleClose } = props;
    const { getDatasToDeploy } = useContext(BackofficeContext);
    const [ loading, setLoading ] = useState(true)
    const [ error, setError ] = useState(null)
    const [ screens, setScreens ] = useState(null)
    const [ updates, setUpdates ] = useState([])
    const [ showOptions, setShowOptions ] = useState(null)
    const [ confirmingDeploy, setConfirmingDeploy ] = useState(null)
    const [ showFormDate, setShowFormDate ] = useState(null)
    const [ showAlertBox, setShowAlertBox ] = useState(null)
    const catalogs = JSON.parse(localStorage.getItem("catalogs"))

    const getScreens = () => {
        const controller = new PosScreenController();
        controller._callback = handleGetScreens;
        controller.index();
    }
    const handleGetScreens = (list, error, status) => {
        switch (status) {
            case 200:
                setScreens(list)
                break
            default: break
        }

        setLoading(false)
    }
    const getDatasToDeployWithDetails = () => {
        const controller = new CompanyController()
        controller._callback = returnGetDatasToDeploy
        controller.tellMeWhatINeed(true)
    }
    const returnGetDatasToDeploy = (response, error, status) => {
        switch (status) {
            case 200:
                switch (type) {
                    case "catalog":
                        explodeCatalogDatas(response)
                        break
                    case "screen":
                        explodeScreenDatas(response)
                        break
                    default: break
                }

                break
            default:
                setError("Une erreur s'est produite lors de la récupération des données à déployer")
                break
        }

        setLoading(false)
    }
    const explodeCatalogDatas = response => {
        if (response.data.catalogs === undefined) return;

        let datas = JSON.parse(response.data.catalogs);
        let updatesTmp = []
        let catalogDatas = []

        for (let id in datas) {
            catalogDatas = datas[id]
            catalogDatas.obj = catalogs.find(_ => _.id === parseInt(id))
            catalogDatas.deployed_type = "now"
            catalogDatas.propose_at = null
            catalogDatas.deployed_loading = false

            if (
                catalogDatas["catalog"]
                || catalogDatas["categories"]["created"].length > 0
                || catalogDatas["categories"]["updated"].length > 0
                || catalogDatas["categories"]["deleted"].length > 0
                || (catalogDatas["subcategories"] !== undefined && catalogDatas["subcategories"]["created"].length > 0)
                || (catalogDatas["subcategories"] !== undefined && catalogDatas["subcategories"]["updated"].length > 0)
                || (catalogDatas["subcategories"] !== undefined && catalogDatas["subcategories"]["deleted"].length > 0)
                || catalogDatas["pricelists"]["created"].length > 0
                || catalogDatas["pricelists"]["updated"].length > 0
                || catalogDatas["pricelists"]["deleted"].length > 0
                || catalogDatas["products"]["created"].length > 0
                || catalogDatas["products"]["updated"].length > 0
                || catalogDatas["products"]["deleted"].length > 0
            ) {
                updatesTmp.push(catalogDatas)
            }
        }

        setUpdates(updatesTmp)
    }
    const explodeScreenDatas = response => {
        if (response.data.screens === undefined) return;

        let datas = JSON.parse(response.data.screens).posscreens;
        let updatesTmp = []
        let screenDatas = []

        for (let id in datas) {
            screenDatas = datas[id]
            screenDatas.obj = screens.find(_ => _.id === parseInt(id))
            screenDatas.deployed_type = "now"
            screenDatas.propose_at = null
            screenDatas.deployed_loading = false

            if (
                screenDatas["screen"]
                || screenDatas["keyboards"]["created"].length > 0
                || screenDatas["keyboards"]["updated"].length > 0
                || screenDatas["keyboards"]["deleted"].length > 0
                || screenDatas["keys"]["created"].length > 0
                || screenDatas["keys"]["updated"].length > 0
                || screenDatas["keys"]["deleted"].length > 0
            ) {
                updatesTmp.push(screenDatas)
            }
        }

        setUpdates(updatesTmp)
    }
    const buildDatas = (datas, index) => {
        let datasToDisplay;

        switch (type) {
            case "catalog":
                datasToDisplay = [
                    {
                        "title": "Catalogue",
                        "key": "catalog"
                    },
                    {
                        "title": "Tarifs",
                        "key": "pricelists"
                    },
                    {
                        "title": "Catégories",
                        "key": "categories"
                    },
                    {
                        "title": "Sous-catégories",
                        "key": "subcategories"
                    },
                    {
                        "title": "Produits",
                        "key": "products"
                    }
                ]

                return <div key={ index } className="catalog">
                    {
                        catalogs.length > 1
                        && <p className="name">Catalog "{ datas.obj.name }"</p>
                    }
                    {
                        datasToDisplay.map((obj, index) => buildUpdate(obj.title, obj.key, index, datas))
                    }
                    <Options item={ "deploy" } show={ (showOptions === datas.obj.id) } options={[{ "class": "blue " + (datas.deployed_type === "now" ? "later" : "now"), "title": (datas.deployed_type === "now" ? "Planifier" : "Déployer"), "action": "handleDeploy" }]} handleAction={ handleOption } />
                    <div className="optionButton" onClick={ () => { handleShowOptions(datas.obj.id) } } />
                    {
                        datas.deployed_type === "later"
                        && <div className="calendar" onClick={ () => { openFormDate(datas.obj.id) } } />
                    }
                    {
                        datas.deployed_loading
                            ? <div className="loader"><LoaderCircle display="loader loaderButtonArrow" strokeWidth="10" /></div>
                            : <p className={ "button " + datas.deployed_type } onClick={ () => { deploy(datas.obj.id) } }><span>{ datas.deployed_type === "now" ? "Déployer" : (datas.propose_at === null ? "Planifier" : buildFrDate(datas.propose_at)) }</span></p>
                    }
                    <div className="clearing" />
                </div>
            case "screen":
                datasToDisplay = [
                    {
                        "title": "Écran",
                        "key": "screen"
                    },
                    {
                        "title": "Claviers",
                        "key": "keyboards"
                    },
                    {
                        "title": "Touches",
                        "key": "keys"
                    }
                ]

                return <div key={ index } className="catalog">
                    {
                        screens.length > 1
                        && <p className="name">Écran "{ datas.obj.name }"</p>
                    }
                    {
                        datasToDisplay.map((obj, index) => buildUpdate(obj.title, obj.key, index, datas))
                    }
                    <Options item={ "deploy" } show={ (showOptions === datas.obj.id) } options={[{ "class": "blue " + (datas.deployed_type === "now" ? "later" : "now"), "title": (datas.deployed_type === "now" ? "Planifier" : "Déployer"), "action": "handleDeploy" }]} handleAction={ handleOption } />
                    <div className="optionButton" onClick={ () => { handleShowOptions(datas.obj.id) } } />
                    {
                        datas.deployed_type === "later"
                        && <div className="calendar" onClick={ () => { openFormDate(datas.obj.id) } } />
                    }
                    {
                        datas.deployed_loading
                            ? <div className="loader"><LoaderCircle display="loader loaderButtonArrow" strokeWidth="10" /></div>
                            : <p className={ "button " + datas.deployed_type } onClick={ () => { deploy(datas.obj.id) } }><span>{ datas.deployed_type === "now" ? "Déployer" : (datas.propose_at === null ? "Planifier" : buildFrDate(datas.propose_at)) }</span></p>
                    }
                    <div className="clearing" />
                </div>
            default: return;
        }
    }
    const buildUpdate = (title, key, index, datas) => {
        switch (key) {
            case "catalog":
                if (datas.catalog) {
                    return <div key={ index } className="update">
                        <p className="name">{ title }</p>
                        <p className="description">1 modification</p>
                    </div>
                }

                break
            case "screen":
                if (datas.screen) {
                    return <div key={ index } className="update">
                        <p className="name">{ title }</p>
                        <p className="description">1 modification</p>
                    </div>
                }

                break
            default:
                const keys = [
                    {
                        "key": "created",
                        "title": "création"
                    },
                    {
                        "key": "updated",
                        "title": "modification"
                    },
                    {
                        "key": "deleted",
                        "title": "suppression"
                    }
                ]

                let description = ""

                for (let id in keys) {
                    if (datas[key] !== undefined && datas[key][keys[id]["key"]] !== undefined && datas[key][keys[id]["key"]].length > 0)
                        description += (description.length > 0 ? ", " : "") + datas[key][keys[id]["key"]].length + " " + keys[id]["title"] + (datas[key][keys[id]["key"]].length > 1 ? "s" : "")
                }

                if (description.length > 0) {
                    return <div key={ index } className="update">
                        <p className="name">{ title }</p>
                        <p className="description">{ description }</p>
                    </div>
                }

                break
        }
    }
    const handleShowOptions = id => {
        if (updates.find(_ => _.obj.id === id).deployed_loading) return;

        setShowOptions(showOptions === id ? null : id)
    }
    const handleOption = action => {
        switch (action) {
            case "handleDeploy":
                let updatesTmp = updates.slice()
                let index = updatesTmp.findIndex(_ => _.obj.id === showOptions)
                if (index < 0) return

                if (updatesTmp[index].deployed_type === "now")
                    updatesTmp[index].deployed_type = "later"
                else
                    updatesTmp[index].deployed_type = "now"

                updatesTmp[index].propose_at = null
                setUpdates(updatesTmp)
                setShowOptions(null)

                if (updatesTmp[index].deployed_type === "later") {
                    openFormDate(updatesTmp[index].obj.id)
                }

                break
            default: break
        }
    }
    const deploy = id => {
        let update = updates.find(_ => _.obj.id === id)
        let itsOk = false

        switch (update.deployed_type) {
            case "now":
                itsOk = true
                break
            case "later":

                if (update.propose_at === null) {
                    setShowAlertBox({
                        "title": "Date obligatoire",
                        "text": "Vous devez planifier une date avant de pouvoir valider le déploiement vers vos boutiques."
                    })
                }
                else {
                    if (new Date(update.propose_at) < new Date()) {
                        setShowAlertBox({
                            "title": "Date incorrecte",
                            "text": "La date de planification ne peut pas être inférieure à la date actuelle."
                        })
                    }
                    else
                        itsOk = true
                }

                break
            default: break
        }

        if (itsOk)
            setConfirmingDeploy(id)
    }
    const deployConfirmed = () => {
        let update = updates.find(_ => _.obj.id === confirmingDeploy)
        let proposedAt = update.propose_at !== null ? update.propose_at : FormBuilder.buildVal("datetime", new Date())

        let updatesTmp = updates.slice()
        updatesTmp[updatesTmp.findIndex(_ => _.obj.id === update.obj.id)].deployed_loading = true
        setUpdates(updatesTmp)

        setConfirmingDeploy(null)

        let controller;

        switch (type) {
            case "catalog":
                controller = new CatalogController();
                break;
            case "screen":
                controller = new PosScreenController();
                break;
            default:
                return;
        }

        controller._callback = returnDeployed
        controller.deploy(update.obj.id, proposedAt)
    }
    const returnDeployed = (response, error, status) => {
        let responseUrl = null

        if (response !== undefined && response.request !== undefined && response.request.responseURL !== undefined)
            responseUrl = response.request.responseURL
        else if (error !== undefined && error.request !== undefined && error.request.responseURL !== undefined)
            responseUrl = error.request.responseURL

        if (responseUrl !== null) {
            let url = responseUrl.split("/")
            let idCatalog = parseInt(url[url.length - 2])
            let updatesTmp = updates.slice()
            updatesTmp[updatesTmp.findIndex(_ => _.obj.id === idCatalog)].deployed_loading = false
            setUpdates(updatesTmp)
        }

        switch (status) {
            case 201:
                getDatasToDeploy(); // force refresh button

                if (updates.length > 1) {
                    setLoading(true);
                    getDatasToDeployWithDetails();
                }
                else
                    handleClose();

                break;
            case 409:
                setError("Certaines touches sont associées à des éléments inconnus d'un catalogue partagé");
                break;
            default:
                setError("Une erreur s'est produite lors de la demande de déploiement");
                break
        }
    }
    const closeConfirmDeploy = () => {
        setConfirmingDeploy(null)
    }
    const openFormDate = id => {
        setShowFormDate(id)
    }
    const closeFormDate = () => {
        setShowFormDate(null)
    }
    const chooseDate = value => {
        let updatesTmp = updates.slice()
        let index = updatesTmp.findIndex(_ => _.obj.id === showFormDate)

        if (index < 0) return

        updatesTmp[index].propose_at = value
        setUpdates(updatesTmp)
        setShowFormDate(null)
    }
    const buildFrDate = value => {
        return "Planifié le " + moment(value).format("DD/MM/YYYY HH:mm")
    }
    const closeAlertBox = () => {
        setShowAlertBox(null)
    }

    useEffect(() => {
        if (type === "screen")
            getScreens()
        else
            getDatasToDeployWithDetails();
    }, [])
    useEffect(() => {
        if (screens !== null)
            getDatasToDeployWithDetails();
    }, [screens])

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox deploy">
                    {
                        loading
                            ? <div className="wrapCenterLoader">
                                <LoaderCircle
                                    display="loader restGETInForm"
                                    strokeWidth="8"/>
                            </div>
                            : <div className="wrapForm justButtonBar">
                                <p className="titleForm center">Modifications {type === "catalog" ? "de catalogues" : "d'écrans"} à déployer</p>
                                {
                                    error !== null
                                    && <p className="globalError">{ error }</p>
                                }
                                <div className="scrollview whiteBg">
                                    {
                                        updates.map((datas, index) => buildDatas(datas, index))
                                    }
                                </div>
                                <button className="cancel left noMarginTop" onClick={ handleClose }>Fermer</button>
                                <div className="clearing" />
                                {
                                    showFormDate !== null
                                    && <CustomDate value={ updates.find(_ => _.obj.id === showFormDate).propose_at !== null ? new Date(updates.find(_ => _.obj.id === showFormDate).propose_at) : null } handleSelect={ chooseDate } handleClose={ closeFormDate } />
                                }
                                {
                                    showAlertBox !== null
                                    && <AlertBox title={ showAlertBox.title } text={ showAlertBox.text } handleClose={ closeAlertBox } />
                                }
                                {
                                    confirmingDeploy !== null
                                    && <ConfirmBox
                                        title="Déployer les modifications"
                                        text="Êtes-vous sûr de vouloir déployer les modifications sur l'ensemble de vos boutiques ?"
                                        textBack="Annuler"
                                        textConfirm="Oui, je suis sûr"
                                        handleClose={ () => { closeConfirmDeploy() } }
                                        handleConfirm={ deployConfirmed } />
                                }
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ToDeployProcess
