import React, {useEffect, useState} from "react"
import InputText from "./InputText";

const InputBarcodePrefix = props => {
    const {attribute, returnType, classname, value, handleChange} = props
    const [values, setValues] = useState([])
    const topics = {
        "notes": "Notes",
        "sales": "Ventes",
        "clients": "Clients",
        "assets": "Avoirs",
        "sellers": "Vendeurs",
        "products": "Produits",
        "categories": "Catégories",
        "subcategories": "Sous-catégories",
        "francs": "Francs",
        "euros": "Euros",
        "weight": "Poids"
    }

    const initValues = () => {
        let list = Object.assign({}, value)

        for (let key in topics) {
            if (list[key] === undefined) {
                list[key] = ""
            }
        }

        list = Object.keys(list).sort().reduce(
            (obj, key) => {
                obj[key] = list[key]
                return obj
            },
            {}
        )

        setValues(list)
    }
    const buildLine = (key, index) => {
        return <div key={index} className={"line"}>
            <InputText
                type={ "text" }
                attribute={ key }
                returnType={ "string" }
                variablePrice={ null }
                classname={ "" }
                classError={ foundDuplicate(key) ? "wrong" : "" }
                value={ values[key] !== undefined ? values[key] : "" }
                placeholder={ "" }
                readonly={ null }
                autoComplete={ null }
                toUppercase={ null }
                autoFocus={ false }
                handleChange={ change }
                handleBlur={ () => {} }
                handleSubmit={ null }
            />
            <p>{ topics[key] }</p>
        </div>
    }
    const change = (attr, returnT, inputValue) => {
        let regex = /^([0-9]{0,2})$/g
        if (!regex.test(inputValue)) return

        let newValue = Object.assign({}, value)
        newValue[attr] = inputValue

        handleChange(attribute, returnType, newValue)
    }
    const foundDuplicate = keyToControl => {
        if (values[keyToControl] === undefined || values[keyToControl].length === 0) return false

        for (let key in topics) {
            if (key === keyToControl) continue

            if (values[key] === values[keyToControl])
                return true
        }

        return false
    }

    useEffect(() => {
        initValues()
    }, [value])

    return (
        <div className={"inputBarcodePrefix" + (classname !== "" ? " " + classname : "")}>
            {
                Object.keys(values).map((key, index) => buildLine(key, index))
            }
        </div>
    )
}

export default InputBarcodePrefix
