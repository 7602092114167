import React from "react";

function Download2Icon (props) {
    const { fill, classname, onclick } = props;

    return (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
     fill={ fill } className={ (classname !== undefined ? classname : "") } onClick={ (onclick !== undefined ? onclick : () => {}) }
    {...props}
  >
    <path
      fill="#00406f"
      d="M19 15q0-.42.298-.702A.96.96 0 0 1 20 14q.42 0 .702.298A.92.92 0 0 1 21 15v2q0 1.088-.526 2.018A3.95 3.95 0 0 1 17 21H7a4 4 0 0 1-2.018-.526 4.14 4.14 0 0 1-1.456-1.457A4 4 0 0 1 3 17V7a3.95 3.95 0 0 1 1.982-3.474A4 4 0 0 1 7 3h2q.42 0 .702.298A.92.92 0 0 1 10 4a.96.96 0 0 1-.298.702A.92.92 0 0 1
 9 5H7q-.948 0-1.474.526Q5.001 6.052 5 7v10q0 .948.526 1.474T7 19h10q.948 0 1.474-.526C19 17.948 19 17.632 19 17zm-8.298-.298Q10.438 15 10 15a.96.96 0 0 1-.702-.298A.96.96 0 0 1 9 14q0-.438.298-.702l10-10Q19.579 3.001 20 3c.421-.001.515.1.702.298q.297.281.298.702c.001.421-.1.515-.298.702zM21 10a.96.96 0 0 1-.298.702A.92.92 0 0 1 20 11a.96.96 0 0 1-.702-.298A.96.96 0 0 1 19 10V4.14q0-.087.246.14.263.212.473.474.229.246.14.246H14a.96.96 0 0 1-.702-.298A.96.96 0 0 1 13 4q0-.42.298-.702A.96.96 0 0 1 14 3h5.86a1.1 1.1 0 0 1 .807.333A1.1 1.1 0 0 1 21 4.14z"
      data-original="#000000"
    />
  </svg>
    );
}

export default Download2Icon;
