import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import LoaderCircle from "../../loader/LoaderCircle";
import FormBuilder from "../../../class/tool/FormBuilder";
import SellerController from "../../../stories/_setting/Sellers/SellerController";
import SellerGroupController from "../../../stories/_setting/SellerGroups/SellerGroupController";
import "../../../css/form/Form.css";

const FormStart = props => {
    const item = "sellers";
    const controller = new SellerController();

    const { object, handleIndex } = props;
    const [ reload, setReload ] = useState(false);
    const [ loadingSellerGroups, setLoadingSellerGroups ] = useState(true);
    const [ values, setValues ] = useState({});
    const [ errors, setErrors ] = useState([]);
    const [ saving, setSaving ] = useState(false);
    const [ globalError, setGlobalError ] = useState("");
    const [ rows, setRows ] = useState([
        {
            label: "Genre",
            attribute: "gender",
            inputType: "select",
            returnType: "string",
            list: [
                {
                    value: "I"
                },
                {
                    value: "W"
                },
                {
                    value: "M"
                },
                {
                    value: "O"
                }
            ],
            dictionary: {
                "I": "Indifférent",
                "W": "Femme",
                "M": "Homme",
                "O": "Autre"
            },
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucun"
        },
        {
            label: "Nom",
            attribute: "lastname",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Nom",
            emptyText: "Aucun"
        },
        {
            label: "Prénom",
            attribute: "firstname",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Prénom",
            emptyText: "Aucun"
        },
        {
            label: "Groupe",
            attribute: "seller_group_id",
            inputType: "select",
            returnType: "int",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucun"
        },
        {
            label: "Code",
            attribute: "code",
            inputType: "code",
            maxLength: 4,
            alphanumeric: false,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            emptyText: "Aucun"
        },
        {
            label: "Réference",
            attribute: "reference",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Réference",
            emptyText: "Aucune"
        }
    ]);

    const handleGetSellerGroups = (list, error) => {
        if(error) {
            setGlobalError("Impossible de récupérer les TVA");
        }

        let rowsTmp = rows.slice();
        let groups = [
            {
                value: "Choisir un groupe",
                id: null
            }, {
                value: "----------",
                id: null
            }
        ];

        for (let i in list) {
            groups.push({
                value: list[i].name,
                id: list[i].id,
                object: list[i]
            });
        }

        rowsTmp[3].list = groups;
        setRows(rowsTmp);
        setLoadingSellerGroups(false);
    }
    const getSellerGroups = () => {
        const controller = new SellerGroupController();
        controller._callback = handleGetSellerGroups;
        controller.index("", 0, 0, false);
    }
    const initValues = () => {
        controller.setFormValues(object, setValues)
    }
    const handleChange = (attribute, returnType, val, strict = false) => {
        let value = FormBuilder.buildVal(returnType, val);
        let filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "int");
        let index = value;

        if (!strict && filtered.length > 0 && filtered[0].list.length > 0) {
            value = parseInt(filtered[0].list[index].id);

            if (filtered[0].list[index].type !== undefined && values[attribute.replace("_id", "_type")] !== undefined) {
                setValues(prev => ({
                    ...prev,
                    [attribute]: value,
                    [attribute.replace("_id", "_type")]: filtered[0].list[index].type
                }));
            }
            else {
                setValues(prev => ({
                    ...prev,
                    [attribute]: value
                }));
            }
        }
        else {
            filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "string")

            if (filtered.length > 0) {
                if (filtered[0].list.filter(_ => _.value === value && _.id !== undefined && _.id === null).length > 0)
                    value = null
            }

            setValues(prev => ({
                ...prev,
                [attribute]: value
            }))
        }
    }
    const callToSave = () => {
        setGlobalError("")
        reinitAllEdits()
        save()
    }
    const reinitAllEdits = () => {
        setErrors([])
    }
    const returnUpdates = () => {
        return controller.returnUpdatesFromCompare(object, values);
    }
    const check422Errors = errorDatas => {
        setGlobalError("Certaines données sont invalides")

        if(errorDatas !== undefined) {
            let keys = Object.keys(errorDatas)
            let fields = ["reference"]

            for(let item in fields)
                if(keys.includes(fields[item]))
                    defineErrors(fields[item], false)
        }
    }
    const defineErrors = (type, empty) => {
        let errorsTmp = errors.slice()

        switch (type) {
            case "name":
                if(empty) errorsTmp["name"] = "Vous devez saisir un nom"
                else errorsTmp["name"] = "Ce nom n'est pas valide"
                break
            default: break
        }

        setErrors(errorsTmp)
    }
    const handleReturnSave = (response, error) => {
        if(error) {
            if(error.response !== undefined) {
                if(error.response.status === 422)
                    check422Errors(error.response.data);
            }
            else
                setGlobalError("Une erreur s'est produite lors de l'enregistrement");
        }
        else {
            setReload(true);
            handleIndex();
        }

        setSaving(false);
    }
    const save = () => {
        const datas = returnUpdates();

        if(Object.keys(datas).length === 0) return;

        setSaving(true);

        controller._callback = handleReturnSave;
        controller.post(datas);
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault();

        callToSave();
    }

    useEffect(() => {
        getSellerGroups();
        initValues();
    }, []);

    return (
        <div className="clearing">
            {
                reload && <Redirect to={ "/" + item } />
            }
            {
                loadingSellerGroups
                    ? <div className="wrapCenterLoader">
                        <LoaderCircle
                            display="loader restGETInForm"
                            strokeWidth="8"/>
                    </div>
                    : <form className="form" onSubmit={handleSubmit}>
                        {
                            globalError !== "" && <p className="globalError">{globalError}</p>
                        }
                        {
                            rows.map((row, index) => (
                                <div key={ index } className="clearing">
                                    <label className={ row.classnameLabel }>{ row.label }</label>
                                    {
                                        FormBuilder.buildInputByType(row, values, errors, handleChange, null, null, handleSubmit, null, null, null, index === 0)
                                    }
                                </div>
                            ))
                        }
                        <button className={"submit " + (saving ? "hide" : "")}>
                            {
                                saving
                                    ? "Enregistrement..."
                                    : "Enregistrer"
                            }
                            <LoaderCircle display="loader submitForm " hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                        </button>
                        <Link to={ "/" + item }>
                            <button className="cancel align">Fermer</button>
                        </Link>
                        <div className="clearing" />
                    </form>
            }
        </div>
    )
}

export default FormStart;
