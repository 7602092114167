import React from "react";

const DropIcon = props => {
    const { fill, classname } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width="1em" height="1em" viewBox="0 0 25 25" fill={ fill } className={(classname !== undefined ? classname : "")}>
            <clipPath id="drop_svg__a">
              <path fill="#646464" d="M.407.984h24v24h-24z" data-original="#000000" />
            </clipPath>
            <g fill="#646464" clipPath="url(#drop_svg__a)">
              <path
                d="M7.907 15.984h-.5a.5.5 0 0 1 0-1h.5a.5.5 0 0 1 0 1m-2.521 0h-1.01a.5.5 0 0 1 0-1h1.01a.5.5 0 0 1 0 1m-3.025-.076a.5.5 0 0 1-.137-.019 2.5 2.5 0 0 1-1.078-.632.5.5 0 1 1 .705-.709c.18.18.404.31.646.38a.5.5 0 0 1-.136.98M.907 13.541a.5.5 0 0 1-.5-.5v-1.01a.5.5 0 0 1 1 0v1.01a.5.5 0 0 1-.5.5m0-3.033a.5.5 0 0 1-.5-.5v-1.01a.5.5 0 0 1 1 0v1.01a.5.5 0 0 1-.5.5m0-3.032a.5.5 0 0 1-.5-.5v-1.01a.5.5 0 0 1 1 0v1.01a.5.5 0 0 1-.5.5m14-1.03a.5.5 0 0 1-.5-.5V4.933a.5.5 0 0 1 1 0v1.011a.5.5 0 0 1-.5.5zm-14-2.003a.5.5 0 0 1-.5-.5v-.459q0-.347.094-.68a.5.5 0 1 1 .962.272 1.5 1.5 0 0 0-.056.408v.46a.5.5 0 0 1-.5.5zm13.92-1.023a.5.5 0 0 1-.48-.36 1.5 1.5 0 0 0-.385-.643.5.5 0 0 1 .703-.71c.301.297.523.668.641 1.072a.5.5 0 0 1-.48.641zM2.344 2.064a.5.5 0 0 1-.14-.98q.342-.1.702-.1h.441a.5.5 0 0 1 0 1h-.44q-.218 0-.422.06a.5.5 0 0 1-.14.02zm10.101-.08h-1.01a.5.5 0 0 1 0-1h1.01a.5.5 0 0 1 0 1m-3.033 0h-1.01a.5.5 0 0 1 0-1h1.011a.5.5 0 1 1 0 1zm-3.032 0H5.37a.5.5 0 0 1 0-1h1.01a.5.5 0 0 1 0 1m8.882 6.146.146.147v-.293a.492.492 0 0 0-.911-.269c.28.062.547.198.765.415m6.645 1.854H17.06c.858.99 1.347 2.227 1.347 3.55a5.456 5.456 0 0 1-5.45 5.45h-2.05a2.48 2.48 0 0 1-1.498-.51l-.002.01v4c0 1.378 1.122 2.5 2.5 2.5h10c1.378 0 2.5-1.122 2.5-2.5v-10c0-1.378-1.122-2.5-2.5-2.5"
                data-original="#000000"
              />
              <path
                d="M9.406 15.691v.793c0 .827.673 1.5 1.5 1.5h2.05a4.455 4.455 0 0 0 4.45-4.45 4.42 4.42 0 0 0-1.303-3.146l-1.55-1.55a.56.56 0 0 0-.794.792.5.5 0 1 1-.706.708l-.5-.5a.574.574 0 0 0-.793 0 .56.56 0 0 0 0 .793l.5.5a.5.5 0 0 1 0 .707.5.5 0 0 1-.707 0l-.75-.75a.574.574 0 0 0-.793 0 .56.56 0 0 0 0 .793l1.25 1.25a.5.5 0 0 1 0 .707.5.5 0 0 1-.707 0l-1.095-1.095a.574.574 0 0 0-.793 0 .557.557 0 0 0 0 .792l2.595 2.595a.5.5 0 0 1 0 .708.5.5 0 0 1-.707 0z"
                data-original="#000000"
              />
            </g>
          </svg>
    )
}

export default DropIcon;
