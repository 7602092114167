import React, { useEffect, useState } from "react"
import moment from "moment"
import saveAs from "file-saver"
import $ from "jquery";
import LoaderCircle from "../../../loader/LoaderCircle"
import EmptyIcon from "../../../icons/EmptyIcon"
import PeriodController from "../../../../stories/_legaldata/Periods/PeriodController"
import DownloadIcon from "../../../icons/DownloadIcon"
import SortingArrowIcon from "../../../icons/SortingArrowIcon"
import WaitingIcon from "../../../icons/WaitingIcon"
import ArchiveController from "../../../../stories/_legaldata/Archives/ArchiveController"
import '../../../../css/page/content/closing/Closing.css'
import '../../../../css/form/Form.css'

const Closing = props => {
    moment.locale('fr')
    const controller = new PeriodController()
    const item = "closings"
    const titleWindow = "Clôtures de caisses"
    const emptyList = "Aucune clôture disponible"
    const [ loading, setLoading ] = useState(false)
    const [ list, setList ] = useState([])
    const [ toUnzip, setToUnzip ] = useState(null)
    const [ fileToSave, setFileToSave ] = useState(null)
    const [ keyToUnzip, setKeyToUnzip ] = useState(null)
    const [ remove, setRemove ] = useState(false)

    const returnGetClosings = (listObjects, error, status) => {
        setLoading(false)

        switch (status) {
            case 404:
                alert("Clotûres introuvables")
                break
            case 200:
                setList(listObjects);
                break
            default:
                alert("Une erreur s'est produite")
                break
        }
    }
    const getClosings = () => {
        setLoading(true);

        controller._callback = returnGetClosings;
        controller.index();
    }
    const buildList = () => {
        if (list.length === 0)
            return blockEmptyList()

        return <div className="wrapPeriods">
            {
                list.map((period, index) => buildLinesPeriod(period, index))
            }
        </div>
    }
    const blockEmptyList = () => {
        return <div className="wrapCenterLoader page">
            <EmptyIcon classname="empty" />
            <p className="emptyLabel">{ emptyList }</p>
        </div>
    }
    const buildLinesPeriod = (period, index, stepColor = 1) => {
        let number = ""
        let nbSales = 0
        let amountTax = 0
        let amountInclTax = 0
        let color = "first"

        if (stepColor === 2) color = "second"
        else if (stepColor === 3) color = "third"

        if (period.period !== null && period.period.closingDatas !== null) {
            number = period.period.closingDatas.number
            nbSales = period.period.closingDatas.nbSales
            amountTax = period.period.closingDatas.amountTax
            amountInclTax = period.period.closingDatas.amountInclTax
        }

        return <div key={ index } className={ "line " + color + (period.period === null ? " unavailable": "") }>
            <table>
                <tbody>
                    <tr>
                        <td className="title">{ period.title }</td>
                        {
                            (period.period !== null && period.period.closingDatas !== null)
                                ? <td className="number">{ number }</td>
                                : <td className="number"><span>-</span></td>
                        }
                        {
                            (period.period !== null && period.period.closingDatas !== null)
                                ? <td className="nbSales"><span>ventes</span>{ nbSales }</td>
                                : <td className="nbSales"><span>-</span></td>
                        }
                        {
                            (period.period !== null && period.period.closingDatas !== null)
                                ? <td className="amountTax"><span>TVA</span>{ amountTax.toFixed(2).replace('.', ',') } Eur</td>
                                : <td className="amountTax"><span>-</span></td>
                        }
                        {
                            (period.period !== null && period.period.closingDatas !== null)
                                ? <td className="amountInclTax"><span>TTC</span>{ amountInclTax.toFixed(2).replace('.', ',') } Eur</td>
                                : <td className="amountInclTax"><span>-</span></td>
                        }
                        {
                            buildDownload(period, stepColor)
                        }
                        {
                            buildShow(period, stepColor)
                        }
                    </tr>
                </tbody>
            </table>
            <div id={ "subs-" + (period.period !== null ? period.period.id : "") } className="subs hidden">
                {
                    period.subs !== undefined
                    && period.subs.map((period, index) => buildLinesPeriod(period, index, (stepColor + 1)))
                }
            </div>
        </div>
    }
    const buildDownload = (period, step) => {
        if (step < 3) {
            return <td className="download">
                {
                    buildIcons(period)
                }
            </td>
        }
        else {
            return <td className="download" colSpan={2}>
                {
                    buildIcons(period)
                }
            </td>
        }
    }
    const buildIcons = period => {
        if (period.period === null)
            return <div />

        if (period.period.closingDatas !== null && period.period.archive !== undefined && period.period.archive !== null)
        {
            if (toUnzip !== null && toUnzip.period.archive.id === period.period.archive.id)
                return <LoaderCircle display="loader downloadArchive" strokeWidth="7" stroke="#FFFFFF" />
            else
                return <DownloadIcon classname="downloadIco" fill="#FFFFFF" onclick={ () => { callDownloadFile(period) } } />
        }
        else
            return <WaitingIcon classname="clockIco" fill="#FFFFFF" />
    }
    const buildShow = (period, step) => {
        if (step < 3) {
            return <td className="show">
                {
                    period.period !== null && period.period.type !== "D"
                        ? <SortingArrowIcon classname="showIco" fill="#FFFFFF" onclick={ () => { showDetails(period.period) } } />
                        : <div />
                }
            </td>
        }
    }
    const showDetails = period => {
        if (period === null) return

        if ($("#subs-" + period.id).hasClass("hidden"))
            $("#subs-" + period.id).removeClass("hidden")
        else
            $("#subs-" + period.id).addClass("hidden")
    }
    const callDownloadFile = period => {
        if (period.period === null || period.period.closingDatas === null || period.period.archive === undefined || period.period.archive === null)
            return

        let controller = new ArchiveController()
        controller._callback = handleCallDownloadFile
        controller.zip(period.period.archive.id)

        setToUnzip(period)
    }
    const handleCallDownloadFile = (response, error, status) => {
        switch (status) {
            case 200:
                setKeyToUnzip(response.data.key)
                downloadFile(response.data)
                break
            default:
                alert("Une erreur s'est produite lors du téléchargement du fichier")
                break
        }
    }
    const downloadFile = datas => {
        let controller = new ArchiveController()
        controller._callback = handleDownloadFile
        controller.download(datas.uri, datas.key)
    }
    const handleDownloadFile = (response, error, status) => {
        switch (status) {
            case 200:
                setFileToSave(response.data)
                break
            default:
                alert("Une erreur s'est produite lors du téléchargement du fichier")
                break
        }
    }
    const saveFile = () => {
        const url = new Blob([fileToSave])
        const nameFile = "cloture_" + toUnzip.period.closingDatas.number + "_" + moment(toUnzip.period.timeSlotBegin).format("DD-MM-YYYY") + ".zip"
        saveAs(url, nameFile)
        setRemove(true)
    }
    const removeFile = () => {
        if (toUnzip.period === null || toUnzip.period.closingDatas === null || toUnzip.period.archive === undefined || toUnzip.period.archive === null)
            return

        let controller = new ArchiveController()
        controller._callback = handleRemoveFile
        controller.unzip(toUnzip.period.archive.id, keyToUnzip)

        setFileToSave(null)
        setKeyToUnzip(null)
        setToUnzip(null)
        setRemove(false)
    }
    const handleRemoveFile = (response, error, status) => {}

    useEffect(() => {
        document.title = "Back office - " + titleWindow

        getClosings()
    }, [])
    useEffect(() => {
        if (toUnzip === null || fileToSave === null) return

        saveFile()
    }, [ toUnzip, fileToSave ])
    useEffect(() => {
        if (!remove || toUnzip === null || keyToUnzip === null) return

        removeFile()
    }, [ toUnzip, remove ])

    return(
        <div className={ "main " + item }>
            {
                loading
                    ? <div className="wrapCenterLoader page">
                        <LoaderCircle display="loader restGETInMain" strokeWidth="8" />
                    </div>
                    : buildList()
            }
        </div>
    )
}

export default Closing
