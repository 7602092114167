import React, { useEffect, useState } from "react"
import cookie from "react-cookies"
import ToolsBar from "./ToolsBar"
import CancelRoundIcon from "../icons/CancelRoundIcon"
import Table from "./Table"
import ManyBoxProduct from "../overbox/product/ManyBox"
import ManyBoxCategory from "../overbox/category/ManyBox"
import ManyBoxSubCategory from "../overbox/subCategory/ManyBox"
import ManyBoxSeller from "../overbox/seller/ManyBox"
import ImportBoxProducts from "../overbox/product/ImportBox";
import ImportBoxClients from "../overbox/client/ImportBox";
import "../../css/history/history.css"

const Listing = props => {
    const { item, itemClass, placeholderSearch, titleNbItems, emptyList, model, title, options, secondaryOptions, filters, filterValues, changeFilters, activeHistory, page, pageSelect, updatePageSelect, list, loading, pagination, priceable, priceableValue, storeableValue, priceableChange, checkable, openable, setPerPage, sortingName, sortingValue, setSortingName, setSortingValue, noBorder, noMarginTopWrapList } = props;
    const [loadingPagePrev, setLoadingPagePrev] = useState(false);
    const [loadingPageNext, setLoadingPageNext] = useState(false);
    const [showOptionNbItems, setShowOptionNbItems] = useState(false);
    const [showFormMany, setShowFormMany] = useState(false);
    const [showFormImport, setShowFormImport] = useState(false);
    const [listChecked, setListChecked] = useState([]);
    const [nbSelected, setNbSelected] = useState(0);

    const updateLoadingPageNext = val => {
        setLoadingPageNext(val)
    }
    const updateLoadingPagePrev = val => {
        setLoadingPagePrev(val)
    }
    const handleChangePerPage = val => {
        setPerPage(val)
        setShowOptionNbItems(false)
        cookie.save('object_per_page', val, { path: '/' })
    }
    const handleOption = action => {
        switch (action) {
            case "handleAddMany":
                handleAddMany();
                break;
            case "handleImport":
                handleImport();
                break;
            default: break
        }
    }
    const handleAddMany = () => {
        setShowFormMany(!showFormMany);
    }
    const handleCloseMany = () => {
        setShowFormMany(false)
        window.location.reload()
    }
    const handleImport = () => {
        setShowFormImport(true);
    }
    const handleCloseImport = () => {
        setShowFormImport(false);
    }
    const handleSort = name => {
        if (sortingName === name)
            setSortingValue(sortingValue === "asc" ? "desc" : "asc")
        else {
            setSortingName(name)
            setSortingValue("asc")
        }
    }
    const handleCheckAll = () => {
        let tmpListChecked = listChecked.slice()

        if (tmpListChecked.length === list.length)
            tmpListChecked = []
        else {
            for (let index in list) {
                if (!tmpListChecked.includes(list[index].id))
                    tmpListChecked.push(list[index].id)
            }
        }

        setNbSelected(tmpListChecked.length)
        setListChecked(tmpListChecked)
    }
    const emptyChecked = () => {
        setNbSelected(0)
        setListChecked([])
    }
    const handleCheckLine = id => {
        let tmpListChecked = listChecked.slice()

        if (tmpListChecked.includes(id))
            tmpListChecked.splice(tmpListChecked.indexOf(id), 1)
        else
            tmpListChecked.push(id);

        setNbSelected(tmpListChecked.length)
        setListChecked(tmpListChecked)
    }
    const handleShowOptionNbItems = () => {
        setShowOptionNbItems(!showOptionNbItems)
    }

    const manyBoxes = {
        "categories": <ManyBoxCategory list={ list } handleClose={ handleCloseMany } />,
        "subcategories": <ManyBoxSubCategory handleClose={ handleCloseMany } />,
        "products": <ManyBoxProduct handleClose={ handleCloseMany } />,
        "sellers": <ManyBoxSeller handleClose={ handleCloseMany } />
    }
    const importBoxes = {
        "products": <ImportBoxProducts type={"product"} handleClose={ handleCloseImport } />,
        "clients": <ImportBoxClients type={"client"} handleClose={ handleCloseImport }/>
    }

    useEffect(() => {
        if (page !== null && page !== pageSelect) {
            if(page > pageSelect) updateLoadingPageNext(true)
            else updateLoadingPagePrev(true)

            updatePageSelect(page)
        }
    }, [page])
    useEffect(() => {
        if (!loading) {
            updateLoadingPageNext(false)
            updateLoadingPagePrev(false)
        }
    }, [loading])

    return (
        <div className={ "main " + itemClass }>
            <ToolsBar
                item={ item }
                title={ title }
                options={ options }
                secondaryOptions={ secondaryOptions }
                filters={ filters }
                filterValues={ filterValues }
                changeFilters={ changeFilters }
                activeHistory={ activeHistory }
                handleOption={ handleOption }
                placeholderSearch={ "Rechercher " + placeholderSearch } />
            <div className={ "manySelectedActions " + (nbSelected > 0 ? "visible" : "") }>
                <CancelRoundIcon classname="cancel" click={ emptyChecked } />
                <p className="nbSelected"><b>{ listChecked.length }</b> sélectionné{ listChecked.length > 1 ? "s" : "" }</p>
                <button className="button delete"><span>Supprimer</span></button>
                <button className="button update"><span>Modifier</span></button>
                <div className="clearing" />
            </div>
            <Table
                item={ item }
                titleNbItems={ titleNbItems }
                emptyList={ emptyList }
                model={ model }
                list={ list }
                loading={ loading }
                priceable={ priceable !== undefined ? priceable : null }
                priceableValue={ priceableValue !== undefined ? priceableValue : null }
                storeableValue={ storeableValue !== undefined ? storeableValue : null }
                priceableChange={ priceableChange !== undefined ? priceableChange : null }
                checkable={ checkable }
                openable={ openable }
                listChecked={ listChecked }
                pagination={ pagination }
                loadingPagePrev={ loadingPagePrev }
                loadingPageNext={ loadingPageNext }
                showOptionNbItems={ showOptionNbItems }
                sortingParam={ sortingName }
                sortingDirection={ sortingValue }
                noBorder={ noBorder !== undefined ? noBorder : false }
                handleChangePerPage={ handleChangePerPage }
                handleCheckLine={ handleCheckLine }
                handleCheckAll={ handleCheckAll }
                handleSort={ handleSort }
                handleShowOptionNbItems={ handleShowOptionNbItems }
                noMarginTopWrapList={ noMarginTopWrapList !== undefined ? noMarginTopWrapList : false }
            />
            {
                showFormMany
                && manyBoxes[item]
            }
            {
                showFormImport
                && importBoxes[item]
            }
        </div>
    )
}

export default Listing
