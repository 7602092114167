import React, { useState, useEffect } from "react"
import $ from "jquery"
import LoaderCircle from "../../loader/LoaderCircle"
import FormInline from "../inline/FormInline"
import SellerGroupController from "../../../stories/_setting/SellerGroups/SellerGroupController"
import Seller from "../../../stories/_setting/Sellers/Seller"
import '../../../css/form/Form.css'

const FormManySeller = props => {
    const { handleClose } = props
    const [ loadingSellerGroups, setLoadingSellerGroups ] = useState(true)
    const [ sellerGroups, setSellerGroups ] = useState([
        {
            value: "Choisir un groupe",
            id: null
        }, {
            value: "----------",
            id: null
        }
    ])
    const [ lines, setLines ] = useState([])
    const [ globalError, setGlobalError ] = useState(null)
    const [ rows, setRows ] = useState([
        {
            label: "Genre",
            attribute: "gender",
            inputType: "select",
            returnType: "string",
            list: [
                {
                    value: "I"
                },
                {
                    value: "W"
                },
                {
                    value: "M"
                },
                {
                    value: "O"
                }
            ],
            dictionary: {
                "I": "Indifférent",
                "W": "Femme",
                "M": "Homme",
                "O": "Autre"
            },
            classnameColumn: "gender",
            classnameInput: "inline",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucun"
        },
        {
            label: "Nom",
            attribute: "lastname",
            inputType: "text",
            returnType: "string",
            classnameColumn: "name",
            classnameInput: "inline",
            classnameNoInput: "",
            placeholder: "Nom",
            emptyText: "Aucun"
        },
        {
            label: "Prénom",
            attribute: "firstname",
            inputType: "text",
            returnType: "string",
            classnameColumn: "name",
            classnameInput: "inline",
            classnameNoInput: "",
            placeholder: "Prénom",
            emptyText: "Aucun"
        },
        {
            label: "Groupe",
            attribute: "seller_group_id",
            inputType: "select",
            returnType: "int",
            list: [],
            dictionary: null,
            classnameColumn: "category",
            classnameInput: "inline",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucun"
        },
        {
            label: "Code",
            attribute: "code",
            inputType: "code",
            maxLength: 4,
            alphanumeric: false,
            classnameColumn: "code",
            classnameInput: "inline",
            classnameNoInput: "",
            emptyText: "Aucun"
        },
        {
            label: "Réference",
            attribute: "reference",
            inputType: "text",
            returnType: "string",
            classnameColumn: "reference",
            classnameInput: "inline",
            classnameNoInput: "",
            placeholder: "Réference",
            emptyText: "Aucune"
        }
    ])

    const handleGetSellerGroups = (list, error) => {
        if(error) {
            setGlobalError("Impossible de récupérer les groupes")
        }

        let groups = [
            {
                value: "Choisir un groupe",
                id: null
            }, {
                value: "----------",
                id: null
            }
        ]

        for (let i in list) {
            groups.push({
                value: list[i].name,
                id: list[i].id,
                object: list[i]
            });
        }

        setSellerGroups(groups)
        setLoadingSellerGroups(false)
    }
    const getSellerGroups = () => {
        const controller = new SellerGroupController();
        controller._callback = handleGetSellerGroups;
        controller.index("", 0, 0, false);
    }
    const initRows = () => {
        let rowsTmp = rows.slice();
        rowsTmp[3].list = sellerGroups;
        setRows(rowsTmp);
    }
    const addLine = (sellerGroup) => {
        const linesTmp = lines.slice();
        const seller = new Seller();
        seller.seller_group_id = sellerGroup;

        linesTmp.push({
            key: lines.length + 1,
            object: seller
        });

        setLines(linesTmp);
    }
    const setObject = (key, object) => {
        let linesTmp = lines.slice();

        for(let index in linesTmp) {
            if (key === linesTmp[index].key) {
                linesTmp[index].object = object;
                break;
            }
        }

        setLines(linesTmp);
    }
    const scrollBottom = () => {
        const id = $(".overbox.manyForm");
        id.scrollTop(id[0].scrollHeight);
    }

    useEffect(() => {
        getSellerGroups();
    }, [])
    useEffect(() => {
        if (!loadingSellerGroups) {
            initRows();
            addLine(-1, -1, -1);
        }
    }, [loadingSellerGroups])
    useEffect(() => {
        scrollBottom();
    }, [lines])

    return (
        <div className="clearing">
            {
                loadingSellerGroups
                    ? <div className="wrapCenterLoader padding">
                        <LoaderCircle
                            display="loader restGETInForm"
                            strokeWidth="8"/>
                    </div>
                    : <div className="form noPadding">
                        {
                            globalError !== null
                            && <p className="globalError">{ globalError }</p>
                        }
                        <table className="manyForm">
                            <tbody>
                                <tr className="header">
                                    <th>Genre</th>
                                    <th>Nom</th>
                                    <th>Prénom</th>
                                    <th>Groupe</th>
                                    <th>Code</th>
                                    <th>Référence</th>
                                    <th className="validate" />
                                </tr>
                                {
                                    lines.map((category, index) => (
                                        <FormInline
                                            key={ index }
                                            object={ category.object }
                                            type="sellers"
                                            rows={ rows }
                                            addLine={ addLine }
                                            setObject={ setObject }
                                            setGlobalError={ setGlobalError } />
                                    ))
                                }
                            </tbody>
                        </table>
                        <button className="cancel noMarginRight" onClick={ handleClose }>Fermer</button>
                        <div className="clearing" />
                    </div>
            }
        </div>
    )
}

export default FormManySeller;
