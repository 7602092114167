import React, { useState } from "react";
import Importer from "../../form/importer/Importer";
import FormChoiceCatalog from "../../form/catalog/FormChoiceCatalog";

const ImportBox = props => {
    const defaultCatalog = JSON.parse(localStorage.getItem("catalog"));
    const { type, handleClose } = props;
    const [ globalError, setGlobalError ] = useState(null);
    const [ catalogId, setCatalogId ] = useState(Object.keys(defaultCatalog).length > 0 ? defaultCatalog.id : null);

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                {
                    catalogId === null
                        ? <div className={ "overbox manyForm" }>
                            {
                                globalError !== null
                                && <p className="error">{ globalError }</p>
                            }
                            <FormChoiceCatalog setCatalogId={ setCatalogId } setGlobalError={ setGlobalError } handleClose={ handleClose } />
                        </div>
                        : <div className={ "overbox import" }>
                            <Importer type={ type } catalogId={ catalogId } handleClose={ handleClose } />
                        </div>
                }
            </div>
        </div>
    )
}

export default ImportBox;
