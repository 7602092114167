import Entity from "../../../class/abstract/Entity";
import Store from "../../_account/Stores/Store";

class StoreSetting extends Entity {
    store_id = null
    store = {}
    timeSlotBegin = 6
    closingMinimalHour = 0
    fiscalYearMonth = 1
    keyPressSound = 0
    saleSound = 0
    assetValidity = 30
    reinitSellerAfterSale = 0
    reinitSellerAfterProduct = 0
    reinitPricelistAfterSale = 0
    reinitPricelistAfterProduct = 0
    kartPrefix = ""
    sleepDelay = 0
    paymentProofLabel = "Repas"
    saleBarcode = 0
    kartBarcode = 0
    assetBarcode = 0
    receiptHeader = "{}"
    receiptFooter = "{}"
    autoBarcodeSellers = 0
    barcodePrefixes = "{}"
    theme = "darkBlue"
    accumulateQuantities = 1
    accountantEmail = ""
    sellerDetailsPrinting = true
    categoryDetailsPrinting = true
    productDetailsPrinting = true
    printPaymentsDetailUnit = false
    activeDiners = false
    forceIdentifiant = false
    forceDiners = false
    timezone = "Europe/Paris"
    displayNotification= true
    delayNotification= 6
    automaticDatetimeControl= true
    allowedProductCreation= false
    allowedPriceUpdate= false
    automaticNotePrinting= false
    sellerInterupt= false
    sellerTimer= null
    zerosixApiKey = null
    url = ""
    urlSync = ""

    constructor(datas = {}) {
        super(datas);
        this.setAttributes(datas);
    }

    setAttributes(datas) {
        let keys = Object.keys(this);
        let key = "";

        for(let i in keys) {
            key = keys[i];

            if (datas[key] !== undefined) {
                switch(key) {
                    case "store":
                        Reflect.set(this, key, new Store(datas[key]))
                        break
                    case "receiptHeader":
                    case "receiptFooter":
                        let val = datas[key]

                        if (val === "\"[]\"")
                            val = JSON.stringify([])

                        Reflect.set(this, key, val)
                        break
                    case "sellerInterupt":
                        Reflect.set(this, key, datas[key] === 1 ? 0 : 1);
                        break;
                    default:
                        Reflect.set(this, key, datas[key])
                        break
                }
            }
        }
    }
}

export default StoreSetting;
