import React from "react"
import DatePicker, { registerLocale } from  "react-datepicker"
import moment from "moment"
import fr from "date-fns/locale/fr"
import InputText from "../../component/form/input/generated/InputText"
import InputSelect from "../../component/form/input/generated/InputSelect"
import InputColor from "../../component/form/input/generated/InputColor"
import InputCode from "../../component/form/input/generated/InputCode"
import InputSwitch from "../../component/form/input/generated/InputSwitch"
import InputTag from "../../component/form/input/generated/InputTag"
import InputRichText from "../../component/form/input/generated/InputRichText"
import InputPricelist from "../../component/form/input/generated/InputPricelist"
import InputBidirectionalList from "../../component/form/input/generated/InputBidirectionalList"
import NoInput from "../../component/form/input/generated/NoInput"
import InputBarcode from "../../component/form/input/generated/InputBarcode"
import InputMultiLinesChoices from "../../component/form/input/generated/InputMultiLinesChoices"
import InputPricelistForCategories from "../../component/form/input/generated/InputPricelistForCategories"
import InputLibrary from "../../component/form/input/generated/InputLibrary"
import InputGrid from "../../component/form/input/generated/InputGrid"
import "react-datepicker/dist/react-datepicker.css"
import InputBarcodePrefix from "../../component/form/input/generated/InputBarcodePrefix";
import InputPhone from "../../component/form/input/generated/InputPhone";
import InputDate from "../../component/form/input/generated/InputDate";
import InputSelectTag from "../../component/form/input/generated/InputSelectTag";
registerLocale("fr", fr)

class FormBuilder {
    static buildInputByType(row, values, errors, handleChange = null, handleBlur = null, handleRecovery = null, handleSubmit = null, handleAdd = null, handleRemove = null, handleBold = null, firstFocus = null, key = null) {
        let inputType = ""
        //let params = []

        if (row.inputType.includes(".")) {
            let explode = row.inputType.split('.')
            inputType = explode[0]

            // for (let i = 1; i < explode.length; i++)
            //     params[i-1] = explode[i]
        }
        else
            inputType = row.inputType

        switch (inputType) {
            case "text":
            case "password":
                return <InputText
                    key={ key !== undefined ? key : 0 }
                    type={ row.inputType }
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    variablePrice={ row.variablePrice !== undefined ? row.variablePrice : null }
                    classname={ row.classnameInput }
                    classError={ Object.keys(errors).includes(row.attribute) ? "wrong" : "" }
                    value={ values[row.attribute] !== undefined ? values[row.attribute] : "" }
                    placeholder={ row.placeholder }
                    tags={ row.tags !== undefined ? row.tags : null }
                    readonly={ row.readOnly !== undefined ? row.readOnly : null }
                    autoComplete={ row.autoComplete !== undefined ? row.autoComplete : null }
                    toUppercase={ row.toUppercase !== undefined ? row.toUppercase : null }
                    autoFocus={ firstFocus !== null ? firstFocus : null }
                    handleChange={ handleChange != null ? handleChange : () => {} }
                    handleBlur={ handleBlur != null ? handleBlur : () => {} }
                    handleSubmit={ handleSubmit != null ? handleSubmit : null }
                />
            case "phone":
                return <InputPhone
                    key={ key !== undefined ? key : 0 }
                    type={ row.inputType }
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    classname={ row.classnameInput }
                    value={ values[row.attribute] !== undefined ? values[row.attribute] : "" }
                    readonly={ row.readOnly !== undefined ? row.readOnly : null }
                    autoComplete={ row.autoComplete !== undefined ? row.autoComplete : null }
                    autoFocus={ firstFocus !== null ? firstFocus : null }
                    handleChange={ handleChange != null ? handleChange : () => {} }
                />
            case "textDisplay":
                return <InputText
                    key={ key !== undefined ? key : 0 }
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    classname={ row.classnameInput }
                    maxChars={ row.maxChars }
                    classError={ Object.keys(errors).includes(row.attribute) ? "wrong" : "" }
                    value={ FormBuilder.getValueLineForDisplayText(row.initialAtribute, row.attribute, values) }
                    toUppercase={ null }
                    placeholder={ row.placeholder }
                    handleChange={ handleChange != null ? handleChange : () => {} }
                />
            case "textHeader":
                return <InputText
                    key={ key !== undefined ? key : 0 }
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    classname={ row.classnameInput }
                    maxChars={ row.maxChars }
                    toBold={ handleBold }
                    classError={ Object.keys(errors).includes(row.attribute) ? "wrong" : "" }
                    value={ FormBuilder.getValueLineForHeaderText(row.initialAtribute, row.attribute, values) }
                    bold={ FormBuilder.getBoldLineForHeaderText(row.initialAtribute, row.attribute, values) }
                    toUppercase={ null }
                    placeholder={ row.placeholder }
                    handleChange={ handleChange != null ? handleChange : () => {} }
                />
            case "select":
                return <InputSelect
                    key={ key !== undefined ? key : 0 }
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    classname={ row.classnameInput }
                    classError={ Object.keys(errors).includes(row.attribute) ? "wrong" : "" }
                    value={ row.returnType === "int" ? FormBuilder.getIndexForValue(row, values) : values[row.attribute] }
                    list={ row.list }
                    dictionary={ row.dictionary }
                    readonly={ row.readOnly !== undefined ? row.readOnly : false }
                    autoFocus={ firstFocus !== null ? firstFocus : null }
                    placeholder={ row.placeholder }
                    loading={ row.loading }
                    handleChange={ handleChange != null ? handleChange : () => {} }
                    handleBlur={ handleBlur != null ? handleBlur : () => {} }
                />
            case "selectTag":
                return <InputSelectTag
                    key={ key !== undefined ? key : 0 }
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    classname={ row.classnameInput }
                    classError={ Object.keys(errors).includes(row.attribute) ? "wrong" : "" }
                    value={ values[row.attribute] }
                    type={ row.type }
                    list={ row.list }
                    readonly={ row.readOnly !== undefined ? row.readOnly : false }
                    autoFocus={ firstFocus !== null ? firstFocus : null }
                    placeholder={ row.placeholder }
                    loading={ row.loading }
                    refreshList={ row.refreshList }
                    handleChange={ handleChange != null ? handleChange : () => {} }
                    handleBlur={ handleBlur != null ? handleBlur : () => {} }
                />
            case "date" :
                return <DatePicker
                    key={ key !== undefined ? key : 0 }
                    locale="fr"
                    dateFormat="dd/MM/yyyy"
                    startDate={ values[row.attribute] !== undefined ? values[row.attribute] : "" }
                    className="input"
                    calendarIcon={ null }
                    clearIcon={ null }
                    onChange={
                        (update) => {
                            handleChange(row.attribute, row.returnType, update)
                        }
                    }
                    showWeekNumbers
                />
            case "datePicker" :
                return <InputDate
                    key={ key !== undefined ? key : 0 }
                    type={ row.inputType }
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    classname={ row.classnameInput }
                    value={ values[row.attribute] !== undefined ? values[row.attribute] : "" }
                    readonly={ row.readOnly !== undefined ? row.readOnly : null }
                    autoComplete={ row.autoComplete !== undefined ? row.autoComplete : null }
                    autoFocus={ firstFocus !== null ? firstFocus : null }
                    handleChange={ handleChange != null ? handleChange : () => {} }
                />
            case "datetime" :
                return <DatePicker
                    key={ key !== undefined ? key : 0 }
                    locale="fr"
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="Heure"
                    dateFormat="d MMMM yyyy, HH:mm"
                    selected={ values[row.attribute] }
                    minDate={ row.minDate !== undefined ? row.minDate : "" }
                    showTimeSelect
                    className="inputText"
                    calendarIcon={ null }
                    clearIcon={ null }
                    onChange={
                        (update) => {
                            handleChange(row.attribute, row.returnType, update)
                        }
                    }
                    placeholderText={row.placeholder}
                    showMonthDropdown
                    showYearDropdown
                    showWeekNumbers
                />
            case "daterange" :
                return <DatePicker
                    key={ key !== undefined ? key : 0 }
                    locale="fr"
                    dateFormat="dd/MM/yyyy"
                    startDate={ values[row.attributeStart] }
                    endDate={ values[row.attributeEnd] }
                    className="input"
                    calendarIcon={ null }
                    clearIcon={ null }
                    onChange={
                        (update) => {
                            handleChange(row.attributeRange, row.returnType, update)
                        }
                    }
                    selectsRange
                    withPortal
                    showWeekNumbers
                />
            case "color":
                return <InputColor
                    key={ key !== undefined ? key : 0 }
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    classname={ row.classnameInput }
                    small={ row.small }
                    value={ values[row.attribute] !== undefined ? values[row.attribute] : "" }
                    handleChange={ handleChange != null ? handleChange : () => {} } />
            case "code":
                return <InputCode
                    key={ key !== undefined ? key : 0 }
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    maxLength={ row.maxLength }
                    alphanumeric={ row.alphanumeric }
                    classname={ row.classnameInput }
                    classError={ Object.keys(errors).includes(row.attribute) ? "wrong" : "" }
                    value={ values[row.attribute] !== undefined ? values[row.attribute] : "" }
                    handleChange={ handleChange != null ? handleChange : () => {} }
                />
            case "switch":
                return <InputSwitch
                    key={ key !== undefined ? key : 0 }
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    label={ row.label }
                    classname={ row.classnameInput }
                    value={ values[row.attribute] !== undefined ? values[row.attribute] : "" }
                    handleChange={ handleChange != null ? handleChange : () => {} }
                />
            case "tag":
                return <InputTag
                    key={ key !== undefined ? key : 0 }
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    inputText={ row.inputText }
                    list={ row.list }
                    dictionary={ row.dictionary }
                    titleButton={ row.titleButton }
                    classname={ row.classname }
                    values={ values[row.attribute] !== undefined ? values[row.attribute] : "" }
                    placeholder={ row.placeholder }
                    loading={ row.loading }
                    removeRules={ row.removeRules }
                    handleChange={ handleChange != null ? handleChange : () => {} }
                />
            case "textarea":
                return <InputRichText
                    key={ key !== undefined ? key : 0 }
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    classname={ row.classnameInput }
                    value={ values[row.attribute] !== undefined ? values[row.attribute] : "" }
                    placeholder={ row.placeholder }
                    focus={ row.focus !== undefined ? row.focus : null }
                    handleChange={ handleChange != null ? handleChange : () => {} }
                    handleBlur={ handleBlur != null ? handleBlur : () => {} }
                />
            case "multiline":
                return <InputMultiLinesChoices
                    key={ key !== undefined ? key : 0 }
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    classname={ row.classnameInput }
                    selectType={ row.selectType }
                    smartList={ row.smartList }
                    list={ row.list }
                    listSelected={ values[row.attribute] !== undefined ? values[row.attribute] : "" }
                    handleChange={ handleChange != null ? handleChange : () => {} } />
            case "pricelist":
                return <InputPricelist
                    key={ key !== undefined ? key : 0 }
                    lines={ values[row.attribute] }
                    pricelists={ row.pricelists }
                    vatrates={ row.vatrates }
                    category={ row.category }
                    classname={ row.classnameInput }
                    shared={ row.shared !== undefined ? row.shared : false }
                    readonly={ row.readOnly !== undefined ? row.readOnly : false }
                    handleChange={ handleChange != null ? handleChange : () => {} }
                    handleAdd={ handleAdd != null ? handleAdd : () => {} }
                    handleRemove={ handleRemove != null ? handleRemove : () => {} }
                    handleRecovery={ handleRecovery != null ? handleRecovery : () => {} } />
            case "pricelistByCategories":
                return <InputPricelistForCategories
                    key={ key !== undefined ? key : 0 }
                    lines={ values[row.attribute] }
                    pricelist={ row.pricelist }
                    vatrates={ row.vatrates }
                    pricelists={ row.pricelists }
                    classname={ row.classnameInput }
                    shared={ row.shared !== undefined ? row.shared : false }
                    readonly={ row.readOnly !== undefined ? row.readOnly : false }
                    handleChange={ handleChange != null ? handleChange : () => {} }
                    handleAdd={ handleAdd != null ? handleAdd : () => {} }
                    handleRemove={ handleRemove != null ? handleRemove : () => {} }
                    handleRecovery={ handleRecovery != null ? handleRecovery : () => {} } />
            case "barcode":
                return <InputBarcode
                    key={ key !== undefined ? key : 0 }
                    barcodes={ values[row.attribute] }
                    storeSettings={ row.storeSettings }
                    classname={ row.classnameInput }
                    readonly={ row.readOnly !== undefined ? row.readOnly : null }
                    handleChange={ handleChange != null ? handleChange : () => {} }
                    handleRecovery={ handleRecovery != null ? handleRecovery : () => {} }
                />
            case "bidirectionalList":
                return <InputBidirectionalList
                    key={ key !== undefined ? key : 0 }
                    attribute={ row.attribute }
                    classname={ row.classnameInput }
                    classnameLabel={ row.classnameLabel }
                    classnameInputLiLeft={ row.classnameInputLiLeft }
                    classnameInputLiRight={ row.classnameInputLiRight }
                    labelLeft={ row.labelLeft }
                    labelRight={ row.labelRight }
                    labelButtonLeft={ row.labelButtonLeft }
                    labelButtonRight={ row.labelButtonRight }
                    emptyText={ row.emptyText }
                    maxHeight={ row.maxHeight }
                    updated={ row.updated }
                    value={ values[row.attribute] !== undefined ? values[row.attribute] : "" }
                    list={ row.list }
                    handleChange={ handleChange != null ? handleChange : () => {} } />
            case "library":
                return <InputLibrary
                    key={ key !== undefined ? key : 0 }
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    type={ row.type !== undefined ? row.type : null }
                    code={ row.code !== undefined ? row.code : null }
                    classname={ row.classnameInput }
                    value={ values[row.attribute] !== undefined ? values[row.attribute] : "" }
                    placeholder={ row.placeholder }
                    handleChange={ handleChange != null ? handleChange : () => {} }
                />
            case "grid":
                return <InputGrid
                    key={ key !== undefined ? key : 0 }
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    classname={ row.classnameInput }
                    value={ values[row.attribute] !== undefined ? values[row.attribute] : "" }
                    minLines={ row.minLines }
                    maxLines={ row.maxLines }
                    minColumns={ row.minColumns }
                    maxColumns={ row.maxColumns }
                    idKeyboard={ row.idKeyboard }
                    allAllowed={ row.allAllowed }
                    setError={ row.setError }
                    handleChange={ handleChange != null ? handleChange : () => {} }
                />
            case "barcodePrefixes":
                return <InputBarcodePrefix
                    key={ key !== undefined ? key : 0 }
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    classname={ row.classnameInput }
                    value={ values[row.attribute] !== undefined ? values[row.attribute] : "" }
                    handleChange={ handleChange != null ? handleChange : () => {} }
                />
            default: break
        }
    }
    static getValueLineForDisplayText(initialAttribute, attribute, values) {
        let lines = typeof values[initialAttribute] === "object" ? values[initialAttribute] : JSON.parse(values[initialAttribute])
        let indexLine = parseInt(attribute.replace("line", "")) - 1

        return lines[indexLine] !== undefined ? lines[indexLine] : ""
    }
    static getValueLineForHeaderText(initialAttribute, attribute, values) {
        let lines = typeof values[initialAttribute] === "object" ? values[initialAttribute] : JSON.parse(values[initialAttribute])
        let indexLine = parseInt(attribute.replace(initialAttribute === "receiptHeader" ? "header" : "footer", ""))

        return lines[indexLine] !== undefined ? lines[indexLine].text : ""
    }
    static getBoldLineForHeaderText(initialAttribute, attribute, values) {
        let lines = typeof values[initialAttribute] === "object" ? values[initialAttribute] : JSON.parse(values[initialAttribute])
        let indexLine = parseInt(attribute.replace(initialAttribute === "receiptHeader" ? "header" : "footer", ""))

        return lines[indexLine] !== undefined ? lines[indexLine].bold : 0
    }
    static getIndexForValue(row, values) {
        let value = 0

        for (let i = 0; i < row.list.length; i++) {
            if (row.list[i].type !== undefined && row.list[i].type !== "") {
                if (
                    row.list[i].id === values[row.attribute]
                    && row.list[i].type === values[row.attribute.replace("_id", "_type")]
                ) {
                    value = i
                    break
                }
            }
            else {
                if (row.list[i].id === values[row.attribute]) {
                    value = i
                    break
                }
            }
        }

        return value
    }
    static buildNoInputByType(row, values, handleEdit) {
        const val = FormBuilder.getValueForNoInput(row, values)

        return <NoInput
            inputType={ row.inputType }
            attribute={ row.attribute }
            classname={ row.classnameNoInput }
            value={ val !== null && val !== "" ? val : row.emptyText }
            edit={ row.edit !== undefined ? row.edit : true }
            readonly={ row.readOnly !== undefined ? row.readOnly : false }
            handleEdit={ handleEdit != null ? handleEdit : () => {} } />
    }
    static getValueForNoInput = (row, values) => {
        let value = ""

        switch (row.inputType) {
            case "select":
                if (row.returnType === "int")
                    value = FormBuilder.getDataByKeyInList(row, values, "value");
                else {
                    if (row.dictionary !== null) {
                        if (values[row.attribute] === null)
                            value = row.dictionary[""];
                        else
                            value = row.dictionary[values[row.attribute]];
                    }
                    else
                        value = values[row.attribute];
                }
                break
            case "textarea":
                value = values[row.attribute]
                break
            default:
                value = values[row.attribute]
                break
        }

        return value
    }
    static getDataByKeyInList = (row, values, data) => {
        let filtered

        if (row.list === undefined) return ""

        if (row.attribute.includes("_id") && values[row.attribute.replace("_id", "_type")] !== undefined)
            filtered = row.list.filter(item => item.id === values[row.attribute] && item.type === values[row.attribute.replace("_id", "_type")])
        else
            filtered = row.list.filter(item => item.id === values[row.attribute])

        if (filtered.length === 0 || filtered[0][data] === undefined) return ""

        return filtered[0][data]
    }
    static buildVal = (returnType, val) => {
        switch (returnType) {
            case "string": return val
            case "int":
                switch (typeof val) {
                    case "string": return parseInt(val)
                    case "object":
                        if (val === null)
                            return null;
                        else
                            return 0;
                    case "undefined":
                        return 0
                    default: return val
                }
            case "float": return val.replace(',', '.')
            case "date":
                return moment(val).format("DD/MM/YYYY")
            case "datetime":
                const datetime = new Date(val)
                const year = datetime.getFullYear()
                const month = (datetime.getMonth() + 1) < 10 ? "0" + (datetime.getMonth() + 1) : (datetime.getMonth() + 1)
                const day = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate()
                const hour = datetime.getHours() < 10 ? "0" + datetime.getHours() : datetime.getHours()
                const minute = datetime.getMinutes() < 10 ? "0" + datetime.getMinutes() : datetime.getMinutes()
                const second = "00"

                return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second
            default: return val
        }
    }

    static handleChange = (rows, setValues, attribute, returnType, val, strict = false) => {
        let value = FormBuilder.buildVal(returnType, val)
        let type = ""
        let filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "int")
        let index = value

        if (!strict && filtered.length > 0 && filtered[0].list.length > 0) {
            value = filtered[0].list[index].id !== null ? parseInt(filtered[0].list[index].id) : null

            if (filtered[0].list[index].type !== undefined) {
                type = filtered[0].list[index].type

                setValues(prev => ({
                    ...prev,
                    [attribute]: value,
                    [attribute.replace("_id", "_type")]: type
                }))
            }
            else {
                setValues(prev => ({
                    ...prev,
                    [attribute]: value
                }))
            }
        }
        else {
            filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "string")

            if (filtered.length > 0) {
                if (filtered[0].list.filter(_ => _.value === value && _.id !== undefined && _.id === null).length > 0)
                    value = null
            }

            setValues(prev => ({
                ...prev,
                [attribute]: value
            }))
        }

        return {
            value: value,
            type: type,
            filtered: filtered,
            index: index
        }
    }
    static returnUpdates = (object, datas, compare = true) => {
        let dataKeys = Object.keys(datas)
        let key = ""
        let updates = {}

        for(let i in dataKeys) {
            key = dataKeys[i]
            //console.log("CHECK UPDATE", key)

            if (object[key] !== undefined) {
                if (compare) {
                    //console.log("COMPARE", typeof datas[key], object[key], datas[key])

                    switch (typeof datas[key]) {
                        case "string":
                            if (object[key] === null && datas[key].length === 0)
                                continue
                            else if (object[key] !== datas[key] && datas[key] !== null)
                                Reflect.set(updates, key, datas[key])

                            break

                        case "object":
                            if (object[key] === null || (object[key].length === 0 && datas[key].length === 0))
                                continue
                            else if (!this.isEqual(object[key], datas[key]) && datas[key] !== null)
                                Reflect.set(updates, key, datas[key])

                            break

                        default:
                            if (object[key] !== datas[key] && datas[key] !== null)
                                Reflect.set(updates, key, datas[key])

                            break
                    }
                }
                else {
                    //console.log("NOT COMPARE", typeof datas[key], object[key], datas[key])

                    if (datas[key] === null)
                        continue

                    switch (typeof datas[key]) {
                        case "string":
                            if (object[key] === null && datas[key].length === 0)
                                continue

                            break

                        default: break
                    }

                    Reflect.set(updates, key, datas[key])
                }
            }
        }

        return updates
    }
    static isEqual = (obj1, obj2) => {
        if (
            (obj1 === null && obj2 !== null)
            || (obj1 !== null && obj2 === null)
        )
            return false

        let props1 = Object.getOwnPropertyNames(obj1)
        let props2 = Object.getOwnPropertyNames(obj2)

        if (props1.length !== props2.length)
            return false;

        for (let i = 0; i < props1.length; i++) {
            let val1 = obj1[props1[i]]
            let val2 = obj2[props1[i]]
            let isObjects = this.isObject(val1) && this.isObject(val2)

            if (isObjects && !this.isEqual(val1, val2) || !isObjects && val1 !== val2)
                return false
        }

        return true
    }
    static isObject = object => {
        return object != null && typeof object === 'object'
    }
}

export default FormBuilder
