import React, {useState, useContext, useEffect} from "react"
import {useHistory, useParams} from "react-router-dom"
import moment from "moment"
import LoaderCircle from "../../../loader/LoaderCircle"
import ListingContext from "../../../../context/ListingContext"
import SaleController from "../../../../stories/_operation/Sale/SaleController"
import "../../../../css/page/content/sale/Preview.css"
import Ticket from "../ticket/Ticket"

const Preview = props => {
    moment.locale('fr')
    const { previousLink } = props
    const [ loading, setLoading ] = useState(true)
    const [ sale, setSale ] = useState(null)
    const { savePage } = useContext(ListingContext)
    const urlParams = useParams()
    const history = useHistory()

    const getSale = () => {
        const controller = new SaleController()
        controller._callback = handleGetSale
        controller.show(urlParams.id)
    }
    const handleGetSale = (object, error, status) => {
        setLoading(false)

        switch (status) {
            case 200:
                setSale(object)
                break
            case 404:
                alert("Cette vente est introuvable")
                break
            default:
                alert("Une erreur s'est produite (" + status + ")")
                break
        }
    }
    const callCloseSheet = () => {
        history.push("/" + previousLink + ((savePage !== undefined && savePage !== 1) ? "/page/" + savePage : ""))
    }
    const returnDatasToPrint = () => {
        const controller = new SaleController()
        return controller.datasToPrint(sale)
    }

    useEffect(() => {
        getSale()
    }, [])

    return(
        <div className="wrapForm preview">
            {
                (loading || sale === null)
                    ? <div className="wrapCenterLoader withButtons">
                        <LoaderCircle display="loader restGETPreview" strokeWidth="8" />
                    </div>
                    : <Ticket datas={ returnDatasToPrint() } />
            }
            <div className="wrapButtons">
                <p className="cancel" onClick={ callCloseSheet }>Fermer</p>
                <div className="clearing" />
            </div>
        </div>
    )
}

export default Preview
