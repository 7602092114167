import React, { useState, useEffect } from "react";
import $ from "jquery";
import LoaderCircle from "../../loader/LoaderCircle";
import CategoryController from "../../../stories/_catalog/Categories/CategoryController";
import SubCategory from "../../../stories/_catalog/SubCategories/SubCategory";
import FormInline from "../inline/FormInline";
import '../../../css/form/Form.css';

const FormManySubCategory = props => {
    const { catalogId, handleClose } = props;
    const [ loadingCategories, setLoadingCategories ] = useState(false);
    const [ categories, setCategories ] = useState([
        {
            value: "Choisir une catégorie",
            id: null
        }, {
            value: "----------",
            id: null
        }
    ]);
    const [ globalError, setGlobalError ] = useState(null);
    const [ lines, setLines ] = useState([]);
    const [ rows, setRows ] = useState([
        {
            label: "Nom",
            attribute: "name",
            inputType: "text",
            returnType: "string",
            classnameColumn: "name",
            classnameInput: "inline",
            classnameNoInput: "",
            placeholder: "Nom",
            emptyText: "Aucun",
            focus: true
        },
        {
            label: "Catégorie",
            attribute: "category_id",
            inputType: "select",
            returnType: "int",
            list: [],
            dictionary: null,
            classnameColumn: "category",
            classnameInput: "inline",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucune"
        },
        {
            label: "Réference",
            attribute: "reference",
            inputType: "text",
            returnType: "string",
            classnameColumn: "reference",
            classnameInput: "inline",
            classnameNoInput: "",
            placeholder: "Réference",
            emptyText: "Aucune"
        }
    ]);
    const catalog = JSON.parse(localStorage.getItem("catalogs")).find(_ => _.id === catalogId);

    const getCategories = () => {
        setLoadingCategories(true)

        const controller = new CategoryController()
        controller._callback = returnGetCategories
        controller.index(catalog.id, "", 0, 0, true)
    }
    const returnGetCategories = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                setGlobalError(null)

                let categoriesTmp = [
                    {
                        value: "Choisir une catégorie",
                        id: null
                    }, {
                        value: "----------",
                        id: null
                    }
                ]

                for (let item in list) {
                    categoriesTmp.push({
                        value: list[item].name,
                        id: list[item].id,
                        object: list[item]
                    })
                }

                setCategories(categoriesTmp)
                break
            default:
                setGlobalError("Impossible de récupérer les catégories")
                break
        }

        setLoadingCategories(false)
    }
    const refreshRows = () => {
        let rowsTmp = rows.slice();
        rowsTmp[rowsTmp.findIndex(_ => _.attribute === "category_id")].list = categories;
        setRows(rowsTmp);
    }
    const addLine = category => {
        const linesTmp = lines.slice()
        const subCategory = new SubCategory()

        subCategory.catalog_id = catalog.id
        subCategory.category_id = category

        linesTmp.push(subCategory)

        setLines(linesTmp)
    }
    const setObject = (key, object) => {
        let linesTmp = lines.slice()

        for(let index in linesTmp) {
            if (key === linesTmp[index].key) {
                linesTmp[index].object = object
                break
            }
        }

        setLines(linesTmp)
    }
    const scrollBottom = () => {
        const id = $(".overbox.manyForm");
        id.scrollTop(id[0].scrollHeight);
    }

    useEffect(() => {
        getCategories();
    }, [])
    useEffect(() => {
        refreshRows();
    }, [categories])
    useEffect(() => {
        if (lines.length === 0)
            addLine(null);
    }, [rows])
    useEffect(() => {
        scrollBottom();
    }, [lines])

    return (
        <div className="clearing">
            {
                loadingCategories
                    ? <div className="wrapCenterLoader padding">
                        <LoaderCircle
                            display="loader restGETInForm"
                            strokeWidth="8"/>
                    </div>
                    : <div className="form noPadding">
                        <p className="titleForm center">Ajouter des sous-catégories dans "<b>{ catalog.name }</b>"</p>
                        {
                            globalError !== null
                            && <p className="globalError">{ globalError }</p>
                        }
                        <table className="manyForm">
                            <tbody>
                                <tr className="header">
                                    <th>Nom</th>
                                    <th>Catégorie</th>
                                    <th>Référence</th>
                                    <th className="validate" />
                                </tr>
                                {
                                    lines.map((subCategory, index) => (
                                        <FormInline
                                            key={ index }
                                            object={ subCategory }
                                            type="subcategories"
                                            rows={ rows }
                                            addLine={ addLine }
                                            setObject={ setObject }
                                            setGlobalError={ setGlobalError } />
                                    ))
                                }
                            </tbody>
                        </table>
                        <button className="cancel noMarginRight" onClick={ handleClose }>Fermer</button>
                        <div className="clearing" />
                    </div>
            }
        </div>
    )
}

export default FormManySubCategory;
