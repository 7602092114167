import React, {useContext, useState} from "react";
import LoaderCircle from "../../../loader/LoaderCircle";
import FormBuilder from "../../../../class/tool/FormBuilder";
import CatalogController from "../../../../stories/_catalog/Catalogs/CatalogController";
import Account from "../../../../stories/_account/Accounts/Account";
import BackofficeContext from "../../../../context/BackofficeContext";
import "../../../../css/page/content/analyze/ExportStats.css";

const ExportStats = props => {
    const { catalogId, cancel } = props;
    const { setValidText } = useContext(BackofficeContext);
    const [ exporting, setExporting ] = useState(false);
    const [ error, setError ] = useState(null);
    const [ values, setValues ] = useState({
        mails: "",
        type: 1
    });
    const [ errors, setErrors ] = useState([]);
    const [ rows, setRows ] = useState([
        {
            label: "Emails supplémentaires (séparer par une virgule)",
            attribute: "mails",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: ""
        },
        {
            label: "Type",
            attribute: "type",
            inputType: "select",
            returnType: "int",
            list: [
                {
                    value: "Données lisibles",
                    id: 1
                },
                {
                    value: "Données bruts",
                    id: 2
                }
            ],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucun"
        }
    ]);
    const user = new Account(JSON.parse(localStorage.getItem("user")));

    const handleChange = (attribute, returnType, val, strict = false) => {
        FormBuilder.handleChange(rows, setValues, attribute, returnType, val, strict);
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault()

        valid()
    }
    const valid = () => {
        setErrors(null)
        reinitAllEdits()
        save()
    }
    const reinitAllEdits = () => {
        setErrors([])
    }
    const check422Errors = errorDatas => {
        setError("Certaines données sont invalides")

        if(errorDatas !== undefined) {
            let keys = Object.keys(errorDatas)
            let fields = ["reference"]

            for(let item in fields)
                if(keys.includes(fields[item]))
                    defineErrors(fields[item], false)
        }
    }
    const defineErrors = (type, empty) => {
        let errorsTmp = errors.slice()

        switch (type) {
            case "name":
                if(empty) errorsTmp["name"] = "Vous devez saisir un nom"
                else errorsTmp["name"] = "Ce nom n'est pas valide"
                break
            default: break
        }

        setErrors(errorsTmp)
    }
    const checkMails = () => {
        const regxp = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)
        const explodeEmails = values.mails.split(",")
        let mails = [user.email];
        let mail;

        for (let index in explodeEmails) {
            mail = explodeEmails[index].trim().toLowerCase();

            if (mail.length > 0) {
                if (regxp.test(mail)) {
                    if (!mails.includes(mail))
                        mails.push(mail);
                }
                else {
                    setError("Un ou plusieurs emails ne sont pas valides : \"" + mail + "\"");
                    return null;
                }
            }
        }

        return mails;
    }
    const buildDatas = () => {
        let datas = {
            mails: [],
            type: values.type
        };

        let mails = checkMails();

        if (mails !== null)
            datas.mails = mails;

        return datas;
    }
    const save = () => {
        if (exporting)
            return;

        let datas = buildDatas();
        if (datas === null)
            return;

        setExporting(true);

        const controller = new CatalogController();
        controller._callback = handleReturnSave;
        controller.export(catalogId, datas.mails, datas.type);
    }
    const handleReturnSave = (response, error, status) => {
        setExporting(false)

        switch (status) {
            case 201:
                setValidText("La demande d'export est enregistrée");
                cancel();
                break
            case 422:
                check422Errors(error)
                break
            default:
                setError("Une erreur s'est produite lors de la demande d'export")
                break
        }
    }

    return(
        <div className={"overlayer"}>
            <div className={"wrapOverbox"}>
                <div className={"overbox start"}>
                    <div className="form">
                        <p className={"titleForm center"}>{ "Exporter le catalogue" }</p>
                        {
                            error !== null && <p className="globalError">{error}</p>
                        }
                        {
                            rows.map((row, index) => (
                                <div key={ index } className="clearing">
                                    {
                                        row.noLabel === undefined
                                        && <label className={ row.classnameLabel }>{ row.label }</label>
                                    }
                                    {
                                        FormBuilder.buildInputByType(row, values, errors, handleChange, null, null, handleSubmit, null, null, null, index === 0)
                                    }
                                </div>
                            ))
                        }
                        <p className={"submit " + (exporting ? "hide" : "")} onClick={valid}>
                            {exporting ? "Enregistrement de la demande..." : "Envoyer"}
                            <LoaderCircle display="loader submitForm " hide={!exporting ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF"/>
                        </p>
                        <p className="cancel align" onClick={cancel}>Fermer</p>
                        <div className="clearing"/>
                    </div>
                </div>
            </div>
        </div>)
}

export default ExportStats;
