import React, { useState, useEffect } from "react";
import LoaderCircle from "../../loader/LoaderCircle";
import FormBuilder from "../../../class/tool/FormBuilder";
import TagController from "../../../stories/_tag/TagController";
import "../../../css/form/Form.css";

const FormStart = props => {
    const { type, object, handleClose, handleIndex } = props;
    const [ values, setValues ] = useState({});
    const [ errors, setErrors ] = useState([]);
    const [ saving, setSaving ] = useState(false);
    const [ globalError, setGlobalError ] = useState(null);
    const [ rows, setRows ] = useState([
        {
            label: "Libellé",
            attribute: "name",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Nom",
            emptyText: "Aucun"
        },
        {
            label: "Icône",
            attribute: "icon",
            inputType: "library",
            type: "tag",
            code: 0,
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Sélectionner une icône"
        },
        {
            label: "Couleur",
            attribute: "color",
            inputType: "color",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucune"
        }
    ]);
    const controller = new TagController();

    const initValues = () => {
        controller.setFormValues(object, setValues)
    }
    const handleChange = (attribute, returnType, val, strict = false) => {
        let value = FormBuilder.buildVal(returnType, val);
        let filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "int");
        let index = value;

        if (!strict && filtered.length > 0 && filtered[0].list.length > 0) {
            value = parseInt(filtered[0].list[index].id);

            if (filtered[0].list[index].type !== undefined && values[attribute.replace("_id", "_type")] !== undefined) {
                setValues(prev => ({
                    ...prev,
                    [attribute]: value,
                    [attribute.replace("_id", "_type")]: filtered[0].list[index].type
                }));
            }
            else {
                setValues(prev => ({
                    ...prev,
                    [attribute]: value
                }));
            }
        }
        else {
            filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "string")

            if (filtered.length > 0) {
                if (filtered[0].list.filter(_ => _.value === value && _.id !== undefined && _.id === null).length > 0)
                    value = null
            }

            setValues(prev => ({
                ...prev,
                [attribute]: value
            }))
        }
    }
    const callToSave = () => {
        setGlobalError(null)
        reinitAllEdits()
        save()
    }
    const reinitAllEdits = () => {
        setErrors([])
    }
    const returnUpdates = () => {
        return controller.returnUpdatesFromCompare(object, values);
    }
    const check422Errors = errorDatas => {
        setGlobalError("Certaines données sont invalides");

        if(errorDatas !== undefined) {
            let keys = Object.keys(errorDatas);
            let fields = ["name"];

            for(let item in fields)
                if(keys.includes(fields[item]))
                    defineErrors(fields[item], false);
        }
    }
    const defineErrors = (type, empty) => {
        let errorsTmp = errors.slice()

        switch (type) {
            case "name":
                if(empty) errorsTmp["name"] = "Vous devez saisir un libellé"
                else errorsTmp["name"] = "Ce libellé n'est pas valide"
                break
            default: break
        }

        setErrors(errorsTmp)
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault();

        callToSave();
    }
    const save = () => {
        const datas = returnUpdates();

        if(Object.keys(datas).length === 0) return;

        setSaving(true);

        controller._callback = handleReturnSave;
        controller.post(type, datas);
    }
    const handleReturnSave = (response, error, status) => {
        setSaving(false)

        switch (status) {
            case 201:
                handleIndex();
                handleClose();
                break;
            case 422:
                check422Errors(error.response.data);
                break;
            default:
                setGlobalError("Une erreur s'est produite lors de l'enregistrement");
                break;
        }
    }

    useEffect(() => {
        initValues();
    }, []);

    return (
        <form className="form" onSubmit={handleSubmit}>
            {
                globalError !== null && <p className="globalError">{globalError}</p>
            }
            {
                rows.map((row, index) => (
                    <div key={ index } className="clearing">
                        <label className={ row.classnameLabel }>{ row.label }</label>
                        {
                            FormBuilder.buildInputByType(row, values, errors, handleChange, null, null, handleSubmit, null, null, null, index === 0)
                        }
                    </div>
                ))
            }
            <button className={"submit " + (saving ? "hide" : "")}>
                {
                    saving
                        ? "Enregistrement..."
                        : "Enregistrer"
                }
                <LoaderCircle display="loader submitForm " hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
            </button>
            <button className="cancel align" onClick={handleClose}>Fermer</button>
            <div className="clearing" />
        </form>
    )
}

export default FormStart;
