import React, { useState } from "react"
import FormBuilder from "../../../class/tool/FormBuilder"
import '../../../css/form/Form.css'

const StoreSettingFormSheet = props => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { values, errors, updated, setGlobalError, handleChange, handleSave } = props
    const [ edit, setEdit ] = useState("")
    const [ rows, setRows ] = useState([
        /*
        {
            label: "Thème de la caisse",
            attribute: "theme",
            inputType: "select",
            returnType: "string",
            list: [
                {
                    "value": "dark",
                    "id": 1
                },
                {
                    "value": "darkBlue",
                    "id": 2
                }
            ],
            dictionary: {
                "dark": "Noir",
                "darkBlue": "Bleu"
            },
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucune"
        },
        */
        {
            label: "Timezone",
            attribute: "timezone",
            inputType: "select",
            returnType: "string",
            list: [
                {
                    "value": "Europe/Paris",
                    "id": 0
                }
            ],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucun"
        },
        {
            label: "Nombre de jours de validité d'un avoir",
            attribute: "assetValidity",
            inputType: "text",
            returnType: "int",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "30",
            emptyText: "Aucun"
        },
        {
            label: "Désignation de la note sans détail",
            attribute: "paymentProofLabel",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Repas",
            emptyText: "Aucun"
        },
        {
            noLabel: true,
            label: "Sélection du tarif à chaque vente",
            attribute: "reinitPricelistAfterSale",
            inputType: "switch",
            returnType: "int",
            classnameInput: "marginTB"
        },
        {
            noLabel: true,
            label: "Sélection du vendeur à chaque vente",
            attribute: "reinitSellerAfterSale",
            inputType: "switch",
            returnType: "int",
            classnameInput: "marginTB"
        },
        {
            noLabel: true,
            label: "Autoriser la sélection simultanée d'un vendeur",
            attribute: "sellerInterupt",
            inputType: "switch",
            returnType: "int",
            classnameInput: "marginTB"
        },
        {
            label: "Désélection automatique du vendeur",
            attribute: "sellerTimer",
            inputType: "select",
            returnType: "int",
            list: [
                {
                    "value": "Jamais",
                    "id": null
                },
                {
                    "value": "15 secondes",
                    "id": 15
                },
                {
                    "value": "30 secondes",
                    "id": 30
                },
                {
                    "value": "45 secondes",
                    "id": 45
                },
                {
                    "value": "60 secondes",
                    "id": 60
                }
            ],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucun"
        },
        {
            noLabel: true,
            label: "Obliger la saisie d'un identifiant",
            attribute: "forceIdentifiant",
            inputType: "switch",
            returnType: "int",
            classnameInput: "marginTB"
        },
        {
            noLabel: true,
            label: "Gestion des tables",
            attribute: "activeDiners",
            inputType: "switch",
            returnType: "int",
            classnameInput: "marginTB"
        },
        {
            noLabel: true,
            label: "Obliger la saisie du nombre de couverts",
            attribute: "forceDiners",
            inputType: "switch",
            returnType: "int",
            classnameInput: "marginTB"
        },
        {
            noLabel: true,
            label: "Afficher les notifications",
            attribute: "displayNotification",
            inputType: "switch",
            returnType: "int",
            classnameInput: "marginTB"
        },
        {
            label: "Délais des notifications",
            attribute: "delayNotification",
            inputType: "select",
            returnType: "int",
            list: [
                {
                    "value": "3 secondes",
                    "id": 3
                },
                {
                    "value": "6 secondes",
                    "id": 6
                },
                {
                    "value": "9 secondes",
                    "id": 9
                },
                {
                    "value": "12 secondes",
                    "id": 12
                }
            ],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucun"
        },
        {
            noLabel: true,
            label: "Son des touches",
            attribute: "keyPressSound",
            inputType: "switch",
            returnType: "int",
            classnameInput: "marginTB"
        },
        {
            noLabel: true,
            label: "Son lors d'un encaissement",
            attribute: "saleSound",
            inputType: "switch",
            returnType: "int",
            classnameInput: "marginTB"
        },
        {
            noLabel: true,
            label: "Contrôler le réglage automatique de la date (conseillé)",
            attribute: "automaticDatetimeControl",
            inputType: "switch",
            returnType: "int",
            classnameInput: "marginTB"
        },
        {
            label: "Clé API Zerosix",
            attribute: "zerosixApiKey",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "aucune",
            emptyText: "Aucun",
            readOnly: user.onlyHivy !== 1,
            edit: user.onlyHivy === 1
        }
    ])

    const buildRow = row => {
        if (edit === row.attribute || ["color", "switch"].includes(row.inputType))
            return FormBuilder.buildInputByType(row, values, errors, change, reinitEdit);
        else
            return FormBuilder.buildNoInputByType(row, values, handleEdit);
    }
    const handleEdit = attribute => {
        setEdit(attribute);
    }
    const change = (attribute, returnType, val, strict = false) => {
        handleChange(rows, attribute, returnType, val, strict);
    }
    const reinitEdit = () => {
        setEdit("");
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault();
        handleSave();
    }

    return (
        <form
            className={ "form fullHeight" + (updated ? " withSavingBar" : "") }
            onSubmit={ handleSubmit } >
            {
                rows.map((row, index) => (
                    <div key={ index } className="clearing">
                        {
                            row.noLabel === undefined
                            && <label className={ row.classnameLabel }>{ row.label }</label>
                        }
                        {
                            buildRow(row)
                        }
                    </div>
                ))
            }
        </form>
    )
}

export default StoreSettingFormSheet
