import Rest from "../../../class/tool/Rest";
import Catalog from "./Catalog";
import FormBuilder from "../../../class/tool/FormBuilder";
import moment from "moment";
moment.locale("fr");

class CatalogController {
    #_rest = new Rest();
    _callback = null;
    _baseURL = "catalogs";
    _baseURLListStoreCatalogs = "companies/{idCompany}/stores/catalogs";

    // perform

    setFormValues(object, setValues) {
        setValues(prev => ({
            ...prev,
            name: object.name,
            color: object.color,
            stores: object.stores
        }))
    }
    returnUpdatesFromCompare(object, datas, compare = true) {
        return FormBuilder.returnUpdates(object, datas, compare)
    }
    checkValues(object) {
        const params = [
            { name: "name", method: "checkName", val: object.name }
        ]
        let errors = {}
        let method

        for (let i in params) {
            method = CatalogController[params[i].method]

            try { method.apply(null, params[i].val) }
            catch (e) { errors.params[i].name = e.message }
        }

        if (Object.keys(errors).length > 0) throw new Error(JSON.stringify(errors))
        else return true
    }

    // Controllers

    index(input = "", page = 1, nb = 25, tree = false, sortingName = "", sortingValue = "") {
        let params = "";
        if (page > 0) params += (params.length === 0 ? "?" : "&") + "page=" + page + "&nb=" + nb;
        if (input.length > 0) params += (params.length === 0 ? "?" : "&") + "input=" + input;
        if (tree) params += (params.length === 0 ? "?" : "&") + "tree=";
        if (sortingName.length > 0) params += (params.length === 0 ? "?" : "&") + "sorton=" + sortingName + "&sortvalue=" + sortingValue;

        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL + params,
            {},
            this.handleIndex)
    }
    handleIndex = (response, error, status = 200) => {
        let listObjects = []
        let pagination = null

        if(status === 200) {
            let data

            if (response.data !== undefined && response.data.data !== undefined) {
                if(response.data.total !== undefined) {
                    pagination = {
                        total: response.data.total,
                        from: response.data.from != null ? response.data.from : 0,
                        to: response.data.to != null ? response.data.to : 0,
                        per_page: response.data.per_page,
                        current_page: response.data.current_page,
                        first_page_url: new URL(response.data.first_page_url).search,
                        last_page_url: new URL(response.data.last_page_url).search,
                        next_page_url: response.data.next_page_url !== null ? new URL(response.data.next_page_url).search : null,
                        prev_page_url: response.data.prev_page_url !== null ? new URL(response.data.prev_page_url).search : null
                    }
                }

                data = response.data.data
            }
            else if (response.data)
                data = response.data

            for(let item in data)
                listObjects.push(new Catalog(data[item]))
        }

        if (this._callback !== null)
            this._callback(listObjects, error, pagination, status)
    }

    indexForCompany(idCompany) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURLListStoreCatalogs.replace("{idCompany}", idCompany),
            {},
            this.handleIndexForCompany)
    }
    handleIndexForCompany = (response, error, status = 200) => {
        let listObjects = []
        const env = JSON.parse(localStorage.getItem("env"))

        if(status === 200) {
            let data = response.data
            let obj

            for(let key in data) {
                obj = {
                    store: {},
                    catalogs: []
                }

                for (let i in env.stores) {
                    if (env.stores[i].id === parseInt(key)) {
                        obj.store = env.stores[i]
                        break
                    }
                }

                for (let i in data[key])
                    obj.catalogs.push(new Catalog(data[key][i]))

                if (obj.catalogs.length > 0)
                    listObjects.push(obj)
            }
        }

        if (this._callback !== null)
            this._callback(listObjects, error, status)
    }

    show(idCatalog) {
        this.#_rest.sendQuery(
            'GET',
            '/catalogs/' + idCatalog,
            {},
            this.handleShow);
    }
    handleShow = (response, error, status) => {
        const object = !error ? new Catalog(response.data) : null;

        if (this._callback !== null)
            this._callback(object, error, status);
    }

    post(datas, needsPricelist = true, context = null) {
        let datasToSend = {};

        if (datas.name !== undefined) datasToSend.name = datas.name;
        if (datas.color !== undefined) datasToSend.color = datas.color;
        if (datas.source_id !== undefined) datasToSend.source_id = datas.source_id;
        if (datas.store_id !== undefined && datas.sharedUuid !== null) datasToSend.store_id = datas.store_id;
        if (datas.sharedUuid !== undefined && datas.sharedUuid !== null) datasToSend.sharedUuid = datas.sharedUuid;

        if (!needsPricelist)
            datasToSend.needspricelist = false;

        if (context !== null)
            this.#_rest._context = context;

        this.#_rest.sendQuery(
            'POST',
            '/catalogs',
            datasToSend,
            this.handlePost);
    }
    handlePost = (response, error, status, context) => {
        if (this._callback !== null)
            this._callback(response, error, status, context)
    }

    put(object, datas) {
        this.#_rest.sendQuery(
            'PUT',
            '/catalogs/' + object.id,
            datas,
            this.handlePut);
    }
    handlePut = (response, error, status) => {
        if (this._callback !== null)
            this._callback(response, error, status);
    }

    delete(object) {
        this.#_rest.sendQuery(
            'DELETE',
            '/' + this._baseURL + '/' + object.id,
            {},
            this.handleDelete);
    }
    handleDelete = (response, error, status) => {
        if (this._callback !== null)
            this._callback(response, error, status);
    }

    rise(storeCatalogId, companyCatalogId) {
        this.#_rest.sendQuery(
            'POST',
            '/catalogs/' + storeCatalogId + '/rise',
            {
                "target_catalog_id": companyCatalogId
            },
            this.handleRise)
    }
    handleRise = (response, error, status) => {
        if (this._callback !== null)
            this._callback(response, error, status)
    }

    deploy(catalogId, proposeAt) {
        this.#_rest.sendQuery(
            'POST',
            '/catalogs/' + catalogId + '/deploy',
            {
                "propose_at": moment(proposeAt).format()
            },
            this.handleDeploy)
    }
    handleDeploy = (response, error, status) => {
        if (this._callback !== null)
            this._callback(response, error, status)
    }

    detach(catalogId) {
        this.#_rest.sendQuery(
            'POST',
            '/catalogs/' + catalogId + '/detach',
            {},
            this.handleDetach)
    }
    handleDetach = (response, error, status) => {
        if (this._callback !== null)
            this._callback(response, error, status)
    }

    export(catalogId, emails, type) {
        this.#_rest.sendQuery(
            'POST',
            '/catalogs/exports',
            {
                emails: JSON.stringify(emails),
                catalog_id: catalogId,
                type: type
            },
            this.handleExport)
    }
    handleExport = (response, error, status) => {
        if (this._callback !== null)
            this._callback(response, error, status)
    }

    import(simulate, datas) {
        this.#_rest.sendQuery(
            'POST',
            '/catalogs/imports' + (simulate ? "/simulate" : ""),
            datas,
            this.handleImport)
    }
    handleImport = (response, error, status) => {
        if (this._callback !== null)
            this._callback(response, error, status)
    }
}

export default CatalogController;
