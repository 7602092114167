import React, {useContext, useEffect, useState} from "react";
import * as xlsx from 'xlsx';
import DropIcon from "../../icons/DropIcon";
import LoaderCircle from "../../loader/LoaderCircle";
import InputSelect from "../input/generated/InputSelect";
import StringTools from "../../../class/tool/StringTools";
import StringIdGenerator from "../../../class/tool/StringIdGenerator";
import PricelistController from "../../../stories/_catalog/Pricelists/PricelistController";
import CatalogController from "../../../stories/_catalog/Catalogs/CatalogController";
import VatRateController from "../../../stories/_catalog/VatRates/VatRateController";
import BackofficeContext from "../../../context/BackofficeContext";
import "../../../css/form/Importer.css";

const Importer = props => {
    const env = JSON.parse(localStorage.getItem("env"));
    const { type, catalogId, handleClose } = props;
    const { setBubbleText, setErrorText, setValidText } = useContext(BackofficeContext);
    const [ datas, setDatas ] = useState(null);
    const [ step, setStep ] = useState(1);
    const [ loader, setLoader ] = useState(false);
    const [ dragActive, setDragActive ] = useState(false);
    const [ catalog, setCatalog ] = useState(JSON.parse(localStorage.getItem("catalogs")).find(_ => _.id === catalogId));
    const [ loaderPricelists, setLoaderPricelists ] = useState(false);
    const [ loaderVatRates, setLoaderVatRates ] = useState(false);
    const [ pricelists, setPricelists ] = useState([]);
    const [ vatRates, setVatRates ] = useState([]);
    const [ columnTypes, setColumnTypes ] = useState([]);
    const [ stateSubmitButton, setStateSubmitButton ] = useState(false);
    const [ closePopup, setClosePopup ] = useState(false);
    const [ importPopup, setImportPopup ] = useState(null);
    const [ loaderSimulate, setLoaderSimulate ] = useState(false);
    const [ loaderImport, setLoaderImport ] = useState(false);
    const defaultColumnTypes = {
        "product": [
            {
                label: "Référence de la catégorie",
                type: "ref",
                keywords: "reference famille,reference categorie,ref famille,ref categorie,ref fam,ref cat",
                key: "categoryReference"
            },
            {
                label: "Référence de la sous-catégorie",
                type: "ref",
                keywords: "reference sous famille,reference sous categorie,ref sous famille,ref sous categorie,ref sous fam,ref sous cat",
                key: "subcategoryReference"
            },
            {
                label: "Référence du produit",
                type: "ref",
                keywords: "reference produit,reference article,ref produit,ref article,ref art,reference,references",
                key: "productReference"
            },
            {
                label: "Nom de la catégorie",
                type: "string",
                keywords: "categorie,famille,nom categorie,nom famille,nom cat,nom fam",
                key: "categoryName"
            },
            {
                label: "Nom de la sous-catégorie",
                type: "string",
                keywords: "sous categorie,sous famille,sous fam,sous cat,nom sous cat,nom sous fam",
                key: "subcategoryName"
            },
            {
                label: "Nom du produit",
                type: "string",
                keywords: "produit,article,nom",
                key: "name"
            },
            {
                label: "Description du produit",
                type: "string",
                keywords: "description,comment,commentaire",
                key: "description"
            },
            {
                label: "Code barre",
                type: "ean",
                keywords: "ean,code barre,code-barre,code barres,codes barres,codes barre,codes-barre,code-barres",
                key: "eans"
            },
            {
                label: "TVA",
                type: "vat",
                keywords: "tva,tax",
                key: "vat"
            },
            {
                label: "Prix de vente",
                type: "price",
                keywords: "prix,montant,prix de vente,prix vente",
                key: "price"
            },
            {
                label: "Prix variable",
                type: "bool",
                keywords: "",
                key: "variablePrice"
            }
        ],
        "client": []
    };

    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();

        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };
    const handleDrop = function(e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            readUploadFile(e.dataTransfer.files[0]);
        }
    };
    const initCatalog = () => {
        if (catalogId === null)
            setCatalog(null);
        else {
            if (catalog === null) {
                const catalogs = JSON.parse(localStorage.getItem("catalogs"));
                setCatalog(catalogs.find(_ => _.id === catalogId));
            }
        }
    }
    const init = () => {
        if (catalogId !== null && type === "product" && pricelists.length === 0) {
            getPricelists();
        }
        else if (catalogId !== null && type === "product" && vatRates.length === 0) {
            getVatRates();
        }
        else if (catalogId !== null && type === "product" && pricelists.length > 0) {
            initColumnTypes();
        }
        else if (type === "client") {
            initColumnTypes();
        }
    }
    const initColumnTypes = () => {
        let columnTypesTmp = [];
        let objToPush;

        switch (type) {
            case "product":
                for (let i in defaultColumnTypes[type]) {
                    switch (defaultColumnTypes[type][i].key) {
                        case "vat":
                        case "price":
                            for (let j in pricelists) {
                                switch (env.type) {
                                    case "store":
                                        objToPush = Object.assign({}, defaultColumnTypes[type][i]);
                                        objToPush.label = objToPush.label + " (" + pricelists[j].name + ")";
                                        objToPush.pricelist = Object.assign({}, pricelists[j]);

                                        if (pricelists[j].main === 0)
                                            objToPush.keywords = "";

                                        columnTypesTmp.push(objToPush);

                                        break;
                                    case "company":
                                        for (let k in env.stores) {
                                            objToPush = Object.assign({}, defaultColumnTypes[type][i]);
                                            objToPush.label = objToPush.label + " " + env.stores[k].shortName + " (" + pricelists[j].name + ")";
                                            objToPush.pricelist = Object.assign({}, pricelists[j]);

                                            if (pricelists[j].main === 0)
                                                objToPush.keywords = "";

                                            columnTypesTmp.push(objToPush);
                                        }

                                        break;
                                    default: break;
                                }
                            }

                            break;
                        default:
                            columnTypesTmp.push(defaultColumnTypes[type][i]);
                            break;
                    }
                }

                break;
            case "client":
                columnTypesTmp = defaultColumnTypes[type];
                break;
            default: break;
        }

        setColumnTypes(columnTypesTmp);
    }
    const initDatas = json => {
        if (datas !== null) {
            setErrorText("Impossible de lire les données provenant du fichier");
            setStep(1)
            setLoader(false);
            return;
        }

        setDatas(buildDatasFromJson(json));
    }
    const updateDatas = json => {
        let datasTmp = buildDatasFromJson(json, true);

        if (datasTmp !== null) {
            setDatas(datasTmp);
            setValidText("Fichier de données ré-importé avec succès");
        }
    }
    const buildDatasFromJson = (jsonFile, reload = false) => {
        if (jsonFile.length === 0) {
            setErrorText("Impossible de lire les données provenant du fichier");

            if (!reload) {
                setStep(1);
                setLoader(false);
            }

            return null;
        }

        let buildDatas =
            reload
            ? Object.assign({}, datas)
            : {
                columns: [],
                lines: []
            };
        let letter = new StringIdGenerator();
        let columns = Object.keys(jsonFile[0]);

        if (reload) {
            buildDatas.lines = [];

            if (columns.length !== buildDatas.columns.length) {
                setErrorText("La structure du fichier est différente");
                return null;
            }
        }
        else {
            // extract columns
            for (let i in columns) {
                buildDatas.columns.push({
                    cell: letter.next(),
                    name: columns[i],
                    type: autoDefineType(buildDatas, columns[i]),
                    disable: false
                });
            }
        }

        // extract lines
        let currentIndex;
        let value;
        for (let column in jsonFile) {
            buildDatas.lines.push([]);
            currentIndex = buildDatas.lines.length - 1;
            letter = new StringIdGenerator();

            for (let i in buildDatas.columns) {
                value = jsonFile[column][buildDatas.columns[i].name];

                if (value !== undefined && value !== null) {
                    if (Array.from(value)[0] === "\"")
                        value = value.substring(1, value.length);

                    if (Array.from(value)[value.length - 1] === "\"")
                        value = value.substring(0, value.length - 1);
                }
                else
                    value = "";

                buildDatas.lines[currentIndex].push({
                    column: buildDatas.columns[i].name,
                    value: value,
                    cell: letter.next() + (parseInt(column)+2)
                });
            }
        }

        return buildDatas;
    }
    const autoDefineType = (datas, name) => {
        let autoType = null;
        let keywords;

        for (let i in columnTypes) {
            if (columnTypes[i].keywords.length === 0)
                continue;

            keywords = columnTypes[i].keywords.split(",");

            for (let j in keywords) {
                if (StringTools.removeAccents(name).replaceAll(" ", "-").toLowerCase().includes(keywords[j].replaceAll(" ", "-"))) {
                    autoType = columnTypes[i];
                    break;
                }
            }

            if (autoType !== null) {
                for (let k in datas.columns) {
                    if (datas.columns[k].type !== null && datas.columns[k].type.key === autoType.key) {
                        autoType = null;
                        break;
                    }
                }

                if (autoType !== null)
                    break;
            }
        }

        return autoType;
    }
    const getPricelists = () => {
        setLoaderPricelists(true);

        const controller = new PricelistController();
        controller._callback = returnGetPricelists;
        controller.index(catalogId, "", 0, 0);
    }
    const returnGetPricelists = (list, error, pagination, status) => {
        setLoaderPricelists(false);

        switch (status) {
            case 200:
                setPricelists(list);
                break;
            default:
                setErrorText("Une erreur s'est produite lors de la récupération des tarifs");
                break;
        }
    }
    const getVatRates = () => {
        setLoaderVatRates(true);

        const controller = new VatRateController();
        controller._callback = returnGetVatRates;
        controller.index();
    }
    const returnGetVatRates = (list, error, status) => {
        setLoaderVatRates(false);

        switch (status) {
            case 200:
                setVatRates(list);
                break;
            default:
                setErrorText("Une erreur s'est produite lors de la récupération des TVAs");
                break;
        }
    }
    const close = () => {
        callToClose();
    }
    const callToClose = () => {
        setClosePopup(!closePopup);
    }
    const contentByStep = () => {
        switch (step) {
            case 1: return importForm();
            case 2: return showColumns();
            default: break;
        }
    }
    const importForm = () => {
        return <form className={"form"} id={"form-file-upload"} onDragEnter={ handleDrag }>
            <input type={"file"} name={"file"} id="input-file-upload" onChange={ onChangeFile } />
            <label className={"inputFile" + (dragActive ? " dragActive" : "")} id="label-file-upload" htmlFor="input-file-upload">
                <div>
                    <DropIcon/>
                    <p className={"mainText"}>Cliquer ici ou déposer votre fichier</p>
                    <p className={"secondaryText"}>Formats acceptés : .xlsx, .xls, .csv</p>
                </div>
            </label>
            {
                dragActive
                && <div id="drag-file-element" onDragEnter={ handleDrag } onDragLeave={ handleDrag } onDragOver={ handleDrag } onDrop={ handleDrop } />
            }
        </form>;
    }
    const showColumns = () => {
        if (datas.length === 0) {
            setErrorText("Le fichier sélectionné ne contient aucune donnée");
            setStep(1);
        }

        return <div className={"interface"}>
            <div className={"header"}>
                <p className={"nbLines"}>{ datas.lines.length } ligne{ (datas.lines.length > 0 ? "s" : "") }</p>
                <form className={"refresher"}>
                    <input type={"file"} name={"file"} id="input-file-upload" onChange={ onChangeFile } />
                    <label className={"inputFile" + (dragActive ? " dragActive" : "")} id="label-file-upload" htmlFor="input-file-upload">
                        <DropIcon classname={"icon"} />
                        <p className={"text"}>Ré-importer le fichier</p>
                    </label>
                </form>
            </div>
            <div className={"core"}>
                {
                    datas.columns.map((column, index) => displayColumnAndValues(datas, column, index))
                }
            </div>
        </div>;
    }
    const displayColumnAndValues = (datas, column, index) => {
        let values = [];
        let value;
        let valueType = null;
        let choices = [
            {
                value: "Définir la colonne",
                id: null
            },
            {
                value: "----------",
                id: null
            },
            {
                value: "Ignorer cette colonne",
                id: -1
            }
        ];

        for (let i in columnTypes) {
            choices.push({
                value: columnTypes[i].label,
                id: i
            });

            if (column.type !== null && column.type.label === columnTypes[i].label) {
                valueType = columnTypes[i].label;
            }
        }

        for (let i in datas.lines) {
            value = datas.lines[i].find(_ => _.column === column.name);
            values.push(value !== undefined ? value : { column: "", value: "", cell: "" });
        }

        if (column.disable)
            valueType = "Ignorer cette colonne";

        return <div key={ index } className={"column" + (column.disable ? " disable" : "") + ((column.type === null && !column.disable) ? " toDefine" : "")} onMouseOver={() => { setBubbleText(column.name) }} onMouseOut={() => { setBubbleText(null) }}>
            <InputSelect attribute={"type_" + column.cell} returnType={"string"} classname={"typeSelector"} value={valueType} list={choices} handleChange={changeType} />
            <ul className={"listValues"}>
                {
                    values.map((value, index) => (
                        <li key={index} className={"value"}>
                            <p className={"cell"}>{ value.cell }</p>
                            <p className={"text"}>{ value.value }</p>
                        </li>
                    ))
                }
            </ul>
        </div>
    }
    const onChangeFile = (e) => {
        e.preventDefault();

        if (e.target.files && e.target.files[0]) {
            readUploadFile(e.target.files[0]);
        }
    }
    const getExtension = filename => {
        return filename.split(".").pop().toLowerCase();
    }
    const readUploadFile = file => {
        if (datas === null)
            setLoader(true);

        if (!["xls", "xlsx", "csv"].includes(getExtension(file.name))) {
            setErrorText("le fichier sélectionner n'est pas pris en charge");
            setStep(1)
            setLoader(false);
            return;
        }

        const reader = new FileReader();

        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = xlsx.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = xlsx.utils.sheet_to_json(worksheet, {defval:""});

            if (datas === null)
                initDatas(json);
            else
                updateDatas(json);

            resetFile();
        };

        reader.readAsArrayBuffer(file);
    }
    const resetFile = () => {
        let elmt = document.getElementById('input-file-upload');

        if (elmt !== null)
            elmt.value = [];
    }
    const changeType = (attribute, returnType, val) => {
        let explode = attribute.split("_");
        if (explode.length !== 2)
            return;

        let cell = explode[1];
        let datasTmp = Object.assign({}, datas);
        let index = datasTmp.columns.findIndex(_ => _.cell === cell);

        if (index < 0)
            return;

        let typeToAttach = null;
        let disable = false;

        switch (val) {
            case "Ignorer cette colonne":
                disable = true;
                break;
            default:
                for (let i in columnTypes) {
                    if (columnTypes[i].label === val) {
                        typeToAttach = columnTypes[i]
                        break;
                    }
                }

                break;
        }

        datasTmp.columns[index].type = typeToAttach;
        datasTmp.columns[index].disable = disable;
        setDatas(datasTmp);
    }
    const checkStateSubmitButton = () => {
        let state = true;

        for (let i in datas.columns) {
            if (datas.columns[i].type === null && !datas.columns[i].disable) {
                state = false;
                break;
            }
        }

        if (state) {
            // check minimal infos
            let minimalKeys = [];

            switch (type) {
                case "product":
                    minimalKeys = ["categoryReference", "productReference", "categoryName", "name", "vat", "price"];
                    break;
                case "client":
                    minimalKeys = ["clientReference", "lastname"];
                    break;
                default: break;
            }

            if (minimalKeys.length === 0)
                state = false;
            else {
                let found;

                for (let i in minimalKeys) {
                    found = false;

                    for (let j in datas.columns) {
                        if (datas.columns[j].type !== null && datas.columns[j].type.key === minimalKeys[i]) {
                            switch (minimalKeys[i]) {
                                case "vat":
                                case "price":
                                    if (datas.columns[j].type.pricelist.main)
                                        found = true;

                                    break;
                                default:
                                    found = true;
                                    break;
                            }

                            if (found)
                                break;
                        }
                    }

                    if (!found) {
                        state = false;
                        break;
                    }
                }
            }
        }

        setStateSubmitButton(state);
    }
    const submit = () => {
        simulate();
    }
    const simulate = () => {
        if (loaderSimulate)
            return;

        setLoaderSimulate(true);

        const controller = new CatalogController();
        controller._callback = returnSimulate;
        controller.import(true, { datas: buildDatasToSend() });
    }
    const returnSimulate = (response, error, status) => {
        setLoaderSimulate(false);

        switch (status) {
            case 201:
                callToImport(response.data);
                break;
            default:
                setErrorText("Une erreur s'est produite lors de la simulation");
                break;
        }
    }
    const goImport = () => {
        if (loaderImport)
            return;

        setLoaderImport(true);

        const controller = new CatalogController();
        controller._callback = returnGoImport;
        controller.import(false, { datas: buildDatasToSend() });
    }
    const returnGoImport = (response, error, status) => {
        setLoaderImport(false);

        switch (status) {
            case 201:
                setValidText("L'import est enregistré, il travaille en arrière plan !");
                handleClose();
                break;
            default:
                setErrorText("Une erreur s'est produite lors de la demande d'import");
                break;
        }
    }
    const callToImport = datas => {
        if (importPopup !== null)
            setImportPopup(null);
        else
            setImportPopup(datas);
    }
    const buildDatasToSend = () => {
        let datasToSend = {};
        let baseKeys = [];

        switch (type) {
            case "product":
                baseKeys = ["categoryReference", "categoryName", "subcategoryReference", "subcategoryName", "productReference", "name", "description", "variablePrice"];
                break;
            case "client":
                baseKeys = ["clientReference", "lastname"];
                break;
            default: break;
        }

        for (let i in datas.lines) {
            switch (type) {
                case "product":
                    datasToSend[parseInt(i) + 2] = buildLineProductToSend(baseKeys, datas.lines[i]);
                    break;
                case "client":
                    datasToSend[parseInt(i) + 2] = buildLineClientToSend(baseKeys, datas.lines[i]);
                    break;
                default: break;
            }
        }

        return datasToSend;
    }
    const buildLineProductToSend = (baseKeys, line) => {
        let objLine = {};
        let indexColumn, formatedValue;

        objLine.catalogReference = {value: catalog.reference, cell: ""};

        for (let j in baseKeys) {
            indexColumn = datas.columns.findIndex(_ => _.type !== null && _.type.key === baseKeys[j]);

            if (indexColumn >= 0) {
                switch (baseKeys[j]) {
                    case "variablePrice":
                        if (["oui","non","yes","no"].includes(line[indexColumn].value.toString().toLowerCase()))
                            formatedValue = ["oui","yes"].includes(line[indexColumn].value.toString().toLowerCase()) ? 1 : 0;
                        else
                            formatedValue = line[indexColumn].value;

                        break;
                    default:
                        formatedValue = line[indexColumn].value;
                        break;
                }

                objLine[baseKeys[j]] = {value: formatedValue, cell: line[indexColumn].cell};
            }
        }

        // EANS
        indexColumn = datas.columns.findIndex(_ => _.type !== null && _.type.key === "eans");
        if (indexColumn >= 0 && line[indexColumn].value.length > 0) {
            let ean;
            let eans = [];
            let explodeEans = line[indexColumn].value.toString().includes(",") ? line[indexColumn].value.toString().split(',') : line[indexColumn].value.toString().split(";");

            for (let i in explodeEans) {
                ean = explodeEans[i];

                if (ean !== undefined && ean !== null) {
                    ean = ean.trim();

                    if (Array.from(ean)[0] === "\"")
                        ean = ean.substring(1, ean.length);

                    if (Array.from(ean)[ean.length - 1] === "\"")
                        ean = ean.substring(0, ean.length - 1);

                    if (ean.length > 0)
                        eans.push(ean);
                }
            }

            objLine["eans"] = {value: eans, cell: line[indexColumn].cell};
        }

        // STORES PRICES & VATS
        objLine["stores"] = [];
        switch (env.type) {
            case "company":
                break;
            case "store":
                objLine["stores"].push({
                    id: env.id,
                    pricelists: []
                });

                let indexFoundPricelist;

                for (let i in pricelists) {
                    indexColumn = datas.columns.findIndex(_ => _.type !== null && _.type.key === "vat" && _.type.pricelist.id === pricelists[i].id);
                    if (indexColumn >= 0) {
                        if (objLine["stores"][0].pricelists.length === 0 || objLine["stores"][0].pricelists.find(_ => _.pricelistReference.value === pricelists[i].reference) === undefined) {
                            objLine["stores"][0].pricelists.push({
                                pricelistReference: { value: pricelists[i].reference, cell: "" }
                            })
                        }

                        indexFoundPricelist = objLine["stores"][0].pricelists.findIndex(_ => _.pricelistReference.value === pricelists[i].reference);

                        if (indexFoundPricelist >= 0) {
                            objLine["stores"][0].pricelists[indexFoundPricelist].vat = { value: getVatRateIdByValue(line[indexColumn].value), cell: line[indexColumn].cell };
                        }
                    }

                    indexColumn = datas.columns.findIndex(_ => _.type !== null && _.type.key === "price" && _.type.pricelist.id === pricelists[i].id);
                    if (indexColumn >= 0) {
                        if (objLine["stores"][0].pricelists.length === 0 || objLine["stores"][0].pricelists.find(_ => _.pricelistReference.value === pricelists[i].reference) === undefined) {
                            objLine["stores"][0].pricelists.push({
                                pricelistReference: { value: pricelists[i].reference, cell: "" }
                            })
                        }

                        indexFoundPricelist = objLine["stores"][0].pricelists.findIndex(_ => _.pricelistReference.value === pricelists[i].reference);

                        if (indexFoundPricelist >= 0) {
                            objLine["stores"][0].pricelists[indexFoundPricelist].price = { value: line[indexColumn].value, cell: line[indexColumn].cell };
                        }
                    }
                }

                break;
            default: break;
        }

        return objLine;
    }
    const buildLineClientToSend = (baseKeys, line) => {
        let objLine = {};
        let indexColumn;

        for (let j in baseKeys) {
            indexColumn = datas.columns.findIndex(_ => _.type !== null && _.type.key === baseKeys[j]);

            if (indexColumn >= 0)
                objLine[baseKeys[j]] = { value: line[indexColumn].value, cell: line[indexColumn].cell };
        }

        return objLine;
    }
    const getVatRateIdByValue = value => {
        let id = 0;
        let objVatRate = vatRates.find(_ => _.value === parseFloat(value.toString().replace(",", ".")));

        if (objVatRate !== undefined)
            id = objVatRate.id;

        return id;
    }
    const buildListImportDetails = datas => {
        if (datas.effects === undefined || datas.effects.Ccat === undefined || datas.effects.Csubcat === undefined || datas.effects.C === undefined || datas.effects.D === undefined || datas.effects.U === undefined)
            return;

        return <ul className={"listDetails"}>
            {
                datas.effects.Ccat.nb > 0
                && <li>{ datas.effects.Ccat.nb } nouvelle{datas.effects.Ccat.nb > 1 ? "s" : ""} catégorie{datas.effects.Ccat.nb > 1 ? "s" : ""}</li>
            }
            {
                datas.effects.Csubcat.nb > 0
                && <li>{ datas.effects.Csubcat.nb } nouvelle{datas.effects.Csubcat.nb > 1 ? "s" : ""} sous-catégorie{datas.effects.Csubcat.nb > 1 ? "s" : ""}</li>
            }
            {
                datas.effects.C.nb > 0
                && <li>{ datas.effects.C.nb } nouveau{datas.effects.C.nb > 1 ? "x" : ""} produit{datas.effects.C.nb > 1 ? "s" : ""}</li>
            }
            {
                datas.effects.U.nb > 0
                && <li>{ datas.effects.U.nb } modification{datas.effects.U.nb > 1 ? "s" : ""}</li>
            }
            {
                datas.effects.D.nb > 0
                && <li>{ datas.effects.D.nb } suppression{datas.effects.D.nb > 1 ? "s" : ""}</li>
            }
        </ul>
    }

    useEffect(() => {
        initCatalog();
    }, [catalogId]);
    useEffect(() => {
        if (datas !== null) {
            setStep(2);
            setLoader(false);

            checkStateSubmitButton();
        }
    }, [datas]);
    useEffect(() => {
        init();
    }, [type, catalogId, pricelists, vatRates]);

    return (
        <div className={"importer"}>
            {
                (loader || loaderPricelists || loaderVatRates || columnTypes.length === 0)
                    ? <div className="wrapCenterLoader">
                        <LoaderCircle display="loader restGETInForm" strokeWidth="8"/>
                    </div>
                    : contentByStep()
            }
            {
                step === 2
                && <button className={"submitButton" + (!stateSubmitButton ? " locked" : "")} onClick={ stateSubmitButton ? () => { submit(); } : () => {} }>
                    {
                        loaderSimulate
                            ? "Simulation en cours..."
                            : "Simuler l'import"
                    }
                    <LoaderCircle display="loader submitForm " hide={!loaderSimulate ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                </button>
            }
            <button className={"cancelButton"} onClick={ close }>Fermer</button>
            {
                closePopup &&
                    <div className={"overlayer hover"}>
                        <div className={"wrapOverbox"}>
                            <div className={"overbox hover validation"}>
                                <div className={"form"}>
                                    <p className={"text"}>Êtes-vous sûr de vouloir quitter l'import de données ?</p>
                                    <button className={"submit red"} onClick={handleClose}>Oui</button>
                                    <button className={"cancel align"} onClick={callToClose}>Non</button>
                                </div>
                            </div>
                        </div>
                    </div>
            }
            {
                importPopup &&
                    <div className={"overlayer hover"}>
                        <div className={"wrapOverbox"}>
                            <div className={"overbox hover validation"}>
                                <div className={"form"}>
                                    <p className={"title noMarginTop"}>Détails de l'import</p>
                                    {
                                        buildListImportDetails(importPopup)
                                    }
                                    <button className={"submit"} onClick={goImport}>
                                        {
                                            loaderImport
                                                ? "Enregistrement..."
                                                : "Importer"
                                        }
                                        <LoaderCircle display="loader submitForm " hide={!loaderImport ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                                    </button>
                                    <button className={"cancel align"} onClick={() => { callToImport(null) }}>Annuler</button>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default Importer;