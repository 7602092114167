import React, {useContext, useEffect, useState} from "react";
import $ from "jquery";
import moment from "moment";
import ToolsBar from "../../../toolsBar/analyze/ToolsBar";
import HourlyChart from "./HourlyChart";
import TableStats from "./TableStats";
import ExportStats from "./ExportStats";
import Stat from "./Stat";
import DashboardController from "../../../../stories/_statistic/Dashboard/DashboardController";
import BackofficeContext from "../../../../context/BackofficeContext";
import StatisticContext from "../../../../context/StatisticContext";
import PricelistController from "../../../../stories/_catalog/Pricelists/PricelistController";
import SubCategoryController from "../../../../stories/_catalog/SubCategories/SubCategoryController";

const Statistics = () => {
    moment.locale('fr');
    const { setErrorText } = useContext(BackofficeContext);
    const [ loading, setLoading ] = useState(false);
    const [ dashboard, setDashboard ] = useState(null);
    const [ pricelistPagination, setPricelistPagination ] = useState(null);
    const [ subCategoryPagination, setSubCategoryPagination ] = useState(null);
    const [ dateType, setDateType ] = useState("day");
    const [ dates, setDates ] = useState({ begin: moment().startOf("day").format(), end: moment().endOf("day").format() });
    const [ statsCA, setStatsCA ] = useState(null);
    const [ statsSales, setStatsSales ] = useState(null);
    const [ refreshHourlyChart, setRefreshHourlyChart ] = useState(false);
    const [ refreshTableStats, setRefreshTableStats ] = useState(false);
    const [ refreshPaymentChart, setRefreshPaymentChart ] = useState(false);
    const [ refreshVatChart, setRefreshVatChart ] = useState(false);
    const [ refreshPricelistChart, setRefreshPricelistChart ] = useState(false);
    const [ refreshCategoryChart, setRefreshCategoryChart ] = useState(false);
    const [ refreshSubCategoryChart, setRefreshSubCategoryChart ] = useState(false);
    const [ refreshProductChart, setRefreshProductChart ] = useState(false);
    const [ datasPayment, setDatasPayment ] = useState([])
    const [ datasVat, setDatasVat ] = useState([])
    const [ datasPricelist, setDatasPricelist ] = useState([])
    const [ datasCategory, setDatasCategory ] = useState([])
    const [ datasSubCategory, setDatasSubCategory ] = useState([])
    const [ datasProduct, setDatasProduct ] = useState([])
    const [ intervalStats, setIntervalStats ] = useState(null);
    const [ exportForm, setExportForm ] = useState(false);
    const [ onTablet, setOnTablet ] = useState(false);
    const env = JSON.parse(localStorage.getItem("env"));
    const INTERVAL_STATS = 300000;
    const paymentModel = [
        {
            name: "nom",
            target: "metadatas",
            type: "text",
            classname: "name",
            object: "name",
            currency: "",
            totalTitle: "",
            chartLabel: true
        },
        {
            name: "quantite",
            target: "datas",
            type: "int",
            classname: "value",
            object: "quantity",
            currency: ""
        },
        {
            name: "montant",
            target: "datas",
            type: "decimal",
            classname: "value",
            object: "amount",
            currency: "€",
            chartValue: true,
            total: true
        }
    ]
    const vatModel = [
        {
            name: "tva",
            target: "metadatas",
            type: "text",
            classname: "name",
            object: "name",
            currency: "",
            totalTitle: "",
            chartLabel: true
        },
        {
            name: "valeur",
            target: "datas",
            type: "decimal",
            classname: "value",
            object: "amount",
            currency: "€",
            chartValue: true,
            total: true
        },
        {
            name: "ttc",
            target: "datas",
            type: "decimal",
            classname: "value",
            object: "inclTax",
            currency: "€",
            total: true
        }
    ]
    const pricelistModel = [
        {
            name: "nom",
            target: "metadatas",
            type: "text",
            classname: "name",
            object: "name",
            currency: "",
            totalTitle: "",
            chartLabel: true
        },
        {
            name: "tva",
            target: "datas",
            type: "decimal",
            classname: "value",
            object: "tax",
            currency: "€",
            total: true
        },
        {
            name: "ttc",
            target: "datas",
            type: "decimal",
            classname: "value",
            object: "inclTax",
            currency: "€",
            chartValue: true,
            total: true
        }
    ]
    const categoryModel = [
        {
            name: "nom",
            target: "metadatas",
            type: "text",
            classname: "name",
            object: "name",
            currency: "",
            onMobile: true,
            totalTitle: "",
            chartLabel: true
        },
        {
            name: "quantite",
            target: "datas",
            type: "int",
            classname: "value",
            object: "quantity",
            currency: "",
            total: true
        },
        {
            name: "ttc",
            target: "datas",
            type: "decimal",
            classname: "value",
            object: "inclTax",
            currency: "€",
            chartValue: true,
            total: true
        }
    ]
    const subCategoryModel = [
        {
            name: "nom",
            target: "metadatas",
            type: "text",
            classname: "name",
            object: "name",
            currency: "",
            totalTitle: "",
            chartLabel: true
        },
        {
            name: "quantite",
            target: "datas",
            type: "int",
            classname: "value",
            object: "quantity",
            currency: "",
            total: true
        },
        {
            name: "ttc",
            target: "datas",
            type: "decimal",
            classname: "value",
            object: "inclTax",
            currency: "€",
            chartValue: true,
            total: true
        }
    ]
    const productModel = [
        {
            name: "nom",
            target: "metadatas",
            type: "text",
            classname: "name",
            object: "name",
            currency: "",
            totalTitle: "",
            chartLabel: true
        },
        {
            name: "quantite",
            target: "datas",
            type: "int",
            classname: "value",
            object: "quantity",
            currency: "",
            total: true
        },
        {
            name: "tva",
            target: "datas",
            type: "decimal",
            classname: "value",
            object: "tax",
            currency: "€",
            total: true
        },
        {
            name: "ttc",
            target: "datas",
            type: "decimal",
            classname: "value",
            object: "inclTax",
            currency: "€",
            chartValue: true,
            total: true
        }
    ]
    let resultsStates = {
        ca: false,
        sales: false,
        payments: false,
        vats: false,
        pricelists: false,
        categories: false,
        subCategories: false,
        products: false
    };

    const init = () => {
        getDashboard();

        if (env.type === "store") {
            listPricelists();
            listSubCategories();
        }
        else {
            setPricelistPagination([]);
            setSubCategoryPagination([]);
        }
    }
    const resize = () => {
        let limitTablet = 1199;
        setOnTablet($(window).width() <= limitTablet);
    }
    const iCallStats = () => {
        if (dashboard === null)
            return;

        let status = false;

        if (dates.begin !== null && dates.end !== null) {
            if (moment(dates.begin).isSame(moment(dates.end), 'day') && moment(dates.begin).isSame(moment(), 'day'))
                status = true;
        }
        else if (dates.begin !== null) {
            if (moment(dates.begin).isSame(moment(), 'day'))
                status = true;
        }

        if (!status)
            return;

        callStats();
    }
    const clearIntervals = () => {
        if (intervalStats !== null)
            clearInterval(intervalStats)
    }
    const initDates = () => {
        setDates({ begin: moment().startOf(dateType).format(), end: moment().endOf(dateType).format() });
    }
    const getDashboard = () => {
        if (Object.keys(dates).length === 0)
            return;

        setLoading(true);

        const controller = new DashboardController();
        controller._callback = returnGetDashboard;
        controller.index({
            dateType: dateType,
            dates: dates
        });
    }
    const returnGetDashboard = (object, error, status) => {
        switch (status) {
            case 200:
                setDashboard(object);
                break
            default:
                setErrorText("Une erreur s'est produite lors de la requête sur cette période. Code [DASH-" + status + "]");
                break;
        }
    }
    const listPricelists = () => {
        let controller = new PricelistController();
        controller._callback = returnListPricelists;
        controller.index(null);
    }
    const returnListPricelists = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                setPricelistPagination(pagination !== undefined ? pagination : null);
                break;
            default:
                setErrorText("Une erreur s'est produite lors de la requête sur cette période. Code [G-PRIC-" + status + "]");
                break;
        }
    }
    const listSubCategories = () => {
        let controller = new SubCategoryController();
        controller._callback = returnListSubCategories;
        controller.index();
    }
    const returnListSubCategories = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                setSubCategoryPagination(pagination !== undefined ? pagination : null);
                break;
            default:
                setErrorText("Une erreur s'est produite lors de la requête sur cette période. Code [G-SUBC-" + status + "]");
                break;
        }
    }
    const launchStats = () => {
        if (loading)
            return;

        getDashboard();
    }
    const callStats = (launchInterval = false) => {
        if (dashboard === null || pricelistPagination === null || subCategoryPagination === null)
            return;

        resultsStates.ca = true;
        resultsStates.sales = true;

        controlResultsStates();

        const controller = new DashboardController();
        controller._callbackCA = returnGetCA;
        controller._callbackSalesStats = returnGetSales;
        controller._callbackPayments = returnGetPayments;
        controller._callbackVats = returnGetVats;
        controller._callbackPricelists = returnGetPricelists;
        controller._callbackCategories = returnGetCategories;
        controller._callbackSubcategories = returnGetSubCategories;
        controller._callbackProducts = returnGetProducts;

        controller.get(dashboard, "ca");
        controller.get(dashboard, "salesStats");

        if (env.type === "store") {
            controller.get(dashboard, "payments");
            controller.get(dashboard, "vats");
            controller.get(dashboard, "categories");
            controller.get(dashboard, "products");

            if (pricelistPagination !== null && pricelistPagination.total > 1)
                controller.get(dashboard, "pricelists");

            if (subCategoryPagination !== null && subCategoryPagination.total > 0)
                controller.get(dashboard, "subcategories");
        }

        if (launchInterval) {
            let tmpIntervalStats = setInterval(iCallStats, INTERVAL_STATS);
            setIntervalStats(tmpIntervalStats);
        }
    }
    const returnGetCA = (object, error, status) => {
        resultsStates.ca = false;

        switch (status) {
            case 200:
                setStatsCA(object);
                break
            default:
                setErrorText("Une erreur s'est produite lors de la requête sur cette période. Code [CA-" + status + "]");
                break;
        }

        controlResultsStates();
    }
    const returnGetSales = (object, error, status) => {
        resultsStates.sales = false;

        switch (status) {
            case 200:
                setStatsSales(object);
                break
            default:
                setErrorText("Une erreur s'est produite lors de la requête sur cette période. Code [SALE-" + status + "]");
                break;
        }

        controlResultsStates();
    }
    const returnGetPayments = (datas, error, status) => {
        resultsStates.payments = false;

        switch (status) {
            case 200:
                setDatasPayment(datas);
                break;
            default:
                setErrorText("Une erreur s'est produite lors de la requête sur cette période. Code [PAYM-" + status + "]");
                break;
        }
    }
    const returnGetVats = (datas, error, status) => {
        resultsStates.vats = false;

        switch (status) {
            case 200:
                setDatasVat(datas);
                break;
            default:
                setErrorText("Une erreur s'est produite lors de la requête sur cette période. Code [VAT-" + status + "]");
                break;
        }
    }
    const returnGetPricelists = (datas, error, status) => {
        resultsStates.pricelists = false;

        switch (status) {
            case 200:
                setDatasPricelist(datas);
                break;
            default:
                setErrorText("Une erreur s'est produite lors de la requête sur cette période. Code [PRIC-" + status + "]");
                break;
        }
    }
    const returnGetCategories = (datas, error, status) => {
        resultsStates.categories = false;

        switch (status) {
            case 200:
                setDatasCategory(datas);
                break;
            default:
                setErrorText("Une erreur s'est produite lors de la requête sur cette période. Code [CAT-" + status + "]");
                break;
        }
    }
    const returnGetSubCategories = (datas, error, status) => {
        resultsStates.subCategories = false;

        switch (status) {
            case 200:
                setDatasSubCategory(datas);
                break;
            default:
                setErrorText("Une erreur s'est produite lors de la requête sur cette période. Code [SUBC-" + status + "]");
                break;
        }
    }
    const returnGetProducts = (datas, error, status) => {
        resultsStates.products = false;

        switch (status) {
            case 200:
                setDatasProduct(datas);
                break;
            default:
                setErrorText("Une erreur s'est produite lors de la requête sur cette période. Code [PROD-" + status + "]");
                break;
        }
    }
    const controlResultsStates = () => {
        let keys = Object.keys(resultsStates);

        for (let i in keys) {
            if (resultsStates[keys[i]]) {
                setLoading(true);
                return;
            }
        }

        setRefreshHourlyChart(true);
        setRefreshTableStats(true);
        setLoading(false);
    }
    const exportStats = () => {
        setExportForm(!exportForm);
    }

    useEffect(() => {
        document.title = "Back office - Analyse";

        window.addEventListener('resize', resize)
        resize();

        init();

        return () => window.removeEventListener('resize', resize)
    }, []);
    useEffect(() => {
        return() => {
            clearIntervals();
        }
    }, [intervalStats]);
    useEffect(() => {
        initDates();
    }, [dateType]);
    useEffect(() => {
        callStats(true);
    }, [dashboard, pricelistPagination, subCategoryPagination]);

    return(
        <StatisticContext.Provider value={{dateType, setDateType, dates, setDates}}>
            {
                exportForm
                && <ExportStats type={"analyse"} cancel={exportStats} dates={{dates: dates}} />
            }
            <div className="main paddingBottom">
                <ToolsBar loading={loading} launchStats={launchStats} launchExport={exportStats} dateTypesAvailable={['D', 'M', 'Q', 'Y']} />
                <HourlyChart refreshStats={refreshHourlyChart} setRefreshStats={setRefreshHourlyChart} ca={statsCA} sales={statsSales} />
                <TableStats refreshStats={refreshTableStats} setRefreshStats={setRefreshTableStats} ca={statsCA} sales={statsSales} />
                {
                    env.type === "store"
                    && <>
                        <Stat idChart="paymentsChart" title="Règlements" hasChart={ true } hasTable={ true } typeChart="pie" onTablet={onTablet} nameAmountChart="amount" reinit={ refreshPaymentChart } model={ paymentModel } datas={ datasPayment } />
                        <Stat idChart="vatsChart" title="TVA" hasChart={ true } hasTable={ true } typeChart="pie" onTablet={onTablet} nameAmountChart="amount" reinit={ refreshVatChart } model={ vatModel } datas={ datasVat } />
                        {
                            pricelistPagination !== null && pricelistPagination.total > 1
                            && <Stat idChart="pricelistsChart" title="Tarifs" hasChart={ true } hasTable={ true } typeChart="bar" onTablet={onTablet} nameAmountChart="inclTax" reinit={ refreshPricelistChart } model={ pricelistModel } datas={ datasPricelist } />
                        }
                        <Stat idChart="categoriesChart" title="Catégories" hasChart={ true } hasTable={ true } typeChart="bar" onTablet={onTablet} nameAmountChart="inclTax" reinit={ refreshCategoryChart } model={ categoryModel } datas={ datasCategory } />
                        {
                            subCategoryPagination !== null && subCategoryPagination.total > 0
                            && <Stat idChart="subCategoriesChart" title="Sous-catégories" hasChart={ true } hasTable={ true } typeChart="bar" onTablet={onTablet} nameAmountChart="inclTax" reinit={ refreshSubCategoryChart } model={ subCategoryModel } datas={ datasSubCategory } />
                        }
                        <Stat idChart="productsChart" title="Produits" hasChart={ true } hasTable={ true } typeChart="bar" onTablet={onTablet} nameAmountChart="inclTax" reinit={ refreshProductChart } model={ productModel } datas={ datasProduct } />
                    </>
                }
            </div>
        </StatisticContext.Provider>
    );
}

export default Statistics;
